import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ callback }) => {
  const { proxy } = getCurrentInstance()

  const state = reactive({
    eventInputText: ''
  })

  const maskingEventInputText = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 3) {
      e.target.value = e.target.value.substr(0, 3)
    }
    
    if (!proxy.$Util.isEmpty(e.target.value) && Number(e.target.value) < 1) {
      e.target.value = 1
    }
    
    state.eventInputText = String(e.target.value)
  }

  const fnSave = async () => {
    if (proxy.$Util.isEmpty(state.eventInputText)) {
      alert('순위를 입력해 주세요.')
      return false
    }
    callback(state.eventInputText)
    proxy.$emit('close')
  }
  return { ...toRefs(state), maskingEventInputText, fnSave }
}
