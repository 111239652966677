import CommonSvc from '@/common/service/CommonSvc'
import { keyBy, groupBy } from 'lodash'

export const state = {
  onDepth1Item: {},
  onDepth2Item: {},
  categoryList: [],
  category2List: []
}
export const getters = {
  getOnDepth1Item: state => {
    state.onDepth1Item = localStorage.onDepth1Item || '{}'
    return JSON.parse(state.onDepth1Item)
  },
  getOnDepth2Item: state => {
    state.onDepth2Item = localStorage.onDepth2Item || '{}'
    return JSON.parse(state.onDepth2Item)
  },
  getCategoryList: state => {
    return state.categoryList
  },
  getCategory2List: state => {
    return state.category2List
  }
}
export const mutations = {
  setOnDepth1Item(state, { onDepth1Item }) {
    state.onDepth1Item = onDepth1Item
    localStorage.onDepth1Item = JSON.stringify(onDepth1Item)
  },
  setOnDepth2Item(state, { onDepth2Item }) {
    state.onDepth2Item = onDepth2Item
    localStorage.onDepth2Item = JSON.stringify(onDepth2Item)
  },
  setCategoryList(state, { categoryList }) {
    state.categoryList = categoryList
  },
  setCategory2List(state, { category2List }) {
    state.category2List = category2List
  }
}
export const actions = {
  updateOnDepth1Item({ commit }, { onDepth1Item = {} }) {
    commit('setOnDepth1Item', { onDepth1Item })
  },
  updateOnDepth2Item({ commit }, { onDepth2Item = {} }) {
    commit('setOnDepth2Item', { onDepth2Item })
  },
  async fetchCategoryList({ commit }) {
    const res = await CommonSvc.postCategoryList()
    if (res.resultCd === '0000') {
      const listData = res.listData
      const depth1 = keyBy(
        listData.filter(category => category.depth === 1),
        item => item.categoryID
      )
      const depth2 = groupBy(
        listData.filter(category => category.depth === 2),
        item => item.upperCategoryID
      )

      for (const key in depth1) {
        depth1[key].categoryList = depth2[key] && [
          ...depth2[key].sort((a, b) => a.categoryOrder - b.categoryOrder)
        ]
      }
      commit('setCategoryList', {
        categoryList: Object.values(depth1)
      })
      commit('setCategory2List', {
        category2List: listData.filter(category => category.depth === 2)
      })
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
