<template>
  <teleport to="body">
    <spinner></spinner>
  </teleport>
  <teleport to="body">
    <header-bar v-show="$Util.isEmpty(modalComponents)" /><!-- 상단 헤더바 -->
    <nav-bar
      v-show="$Util.isEmpty(modalComponents)"
    /><!-- 하단 네비게이션바 -->
    <div
      v-show="$Util.isEmpty(modalComponents)"
      class="page bg"
      style="height: auto;"
      v-if="bannerShow"
    >
      <div class="inner bg">
        <div class="page_con">
          <div class="home_visual mo">
            <swiper
              tag="ul"
              class="home_visual_ul"
              :slides-per-view="1"
              :autoplay="{ delay: 3000 }"
              autoHeight
              @slideChange="onSlideChange"
            >
              <swiper-slide
                tag="li"
                class="home_visual_li"
                v-for="(item, index) in bannerList"
                :key="index"
                @click="fnMove(item)"
                ><img :src="item.imgUrl" alt="" @error="displayNone"
              /></swiper-slide>
              <div class="pager" v-if="!$Util.isEmpty(bannerList)">
                <ul>
                  <li
                    :class="{ on: index === activeIndex }"
                    v-for="(item, index) in bannerList"
                    :key="index"
                  ></li>
                </ul>
              </div>
            </swiper>
          </div>
          <div class="home_visual pc">
            <swiper
              tag="ul"
              class="home_visual_ul"
              :slides-per-view="1"
              :autoplay="{ delay: 3000 }"
              autoHeight
              @slideChange="onSlideChange"
            >
              <swiper-slide
                tag="li"
                class="home_visual_li"
                v-for="(item, index) in bannerList"
                :key="index"
                @click="fnMove(item)"
                ><img :src="item.imgUrl" alt=""
              /></swiper-slide>
              <div class="pager" v-if="!$Util.isEmpty(bannerList)">
                <ul>
                  <li
                    :class="{ on: index === activeIndex }"
                    v-for="(item, index) in bannerList"
                    :key="index"
                  ></li>
                </ul>
              </div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
    <router-view v-slot="{ Component }" v-show="$Util.isEmpty(modalComponents)">
      <component :is="Component" :key="route.fullPath"></component>
    </router-view>
  </teleport>
  <teleport to="body">
    <push-alarm />
  </teleport>
  <!-- 전체화면 modal component -->
  <component
    :index="index"
    :is="item.component"
    v-bind="item"
    @close="fnCloseModal"
    v-for="(item, index) in modalComponents"
    :key="index"
  />
  <!-- alert modal component -->
  <component
    :index="index"
    :is="item.component"
    v-bind="item"
    @close="fnCloseModalAlert"
    v-for="(item, index) in modalAlertComponents"
    :key="index"
  />
</template>

<script>
import HeaderBar from '@/components/common/layouts/header-bar/index.vue'
import NavBar from '@/components/common/layouts/nav-bar/index.vue'
import Spinner from '@/components/common/layouts/spinner/index.vue'
import PushAlarm from '@/components/common/layouts/push-alarm/index.vue'
import { componentState } from './index.js'

export default {
  name: 'components-index',
  components: { HeaderBar, NavBar, Spinner, PushAlarm },
  setup() {
    return {
      ...componentState()
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
  transition: opacity 0.3s;
}
</style>
