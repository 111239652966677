import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
/**
 * Customer API Service
 * @class SignSvc
 */
class CustomerSvc extends ServiceExec {
  /**
   * 공지사항
   * @param params
   * @returns {*}
   */
  postNotice(params = {}) {
    return this.post(`/api/customer/notice`, params).then(response => {
      return response.data
    })
  }

  /**
   * 공지사항 상세
   * @param params
   * @returns {*}
   */
  postNoticeDetail(params = {}) {
    return this.post(`/api/customer/notice/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * FAQ
   * @param params
   * @returns {*}
   */
  postFaq(params = {}) {
    return this.post(`/api/customer/faq`, params).then(response => {
      return response.data
    })
  }

  /**
   * 1:1 문의 – 등록시 회원정보 값
   * @param params
   * @returns {*}
   */
  postMyQuestionInfo(params = {}) {
    return this.post(`/api/customer/myquestion/info`, params).then(response => {
      return response.data
    })
  }

  /**
   * 1:1문의 – 문의등록/수정
   * @param params
   * @returns {*}
   */
  postMyQuestionSave(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      formData.append(key, value)
    })
    return this.multiPost(`/api/customer/myquestion/save`, formData).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 1:1문의 – 문의내역 상세
   * @param params
   * @returns {*}
   */
  postMyQuestionDetail(params = {}) {
    return this.post(`/api/customer/myquestion/detail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 1:1문의 – 리스트
   * @param params
   * @returns {*}
   */
  postMyQuestionList(params = {}) {
    return this.post(`/api/customer/myquestion/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 1:1문의 – 삭제
   * @param params
   * @returns {*}
   */
  postMyQuestionDelete(params = {}) {
    return this.post(`/api/customer/myquestion/delete`, params).then(
      response => {
        return response.data
      }
    )
  }

  // 신고하기
  postCustomerReport(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      if (key === 'file' && typeof value === 'object') {
        for (let i = 0; i < value.length; i++) {
          formData.append('file', value[i], value[i].name)
        }
      } else {
        formData.append(key, value)
      }
    })
    return this.multiPost(`/api/customer/report`, formData).then(
      res => res.data
    )
  }

  // 신고 리스트
  postCustomerReportList(params = {}) {
    return this.post(`/api/customer/reportList`, params).then(res => res.data)
  }

  // 신고 상세페이지
  postCustomerReportDetail(params = {}) {
    return this.post(`/api/customer/reportDetail`, params).then(res => res.data)
  }

  // 신고 삭제
  postCustomerReportDelete(params = {}) {
    return this.post(`/api/customer/reportDelete`, params).then(res => res.data)
  }

  // 올림픽 퀴즈 답변 작성
  postEventOlympicQuizAnswer(params = {}) {
    return this.post(`/api/event/olympicQuizAnswer`, params).then(res => res.data)
  }
}
export default new CustomerSvc()
