import { getCurrentInstance, reactive, toRefs } from 'vue'
// import { useRoute } from 'vue-router'

export const componentState = ({ data, callback }) => {
  // const { proxy } = getCurrentInstance()
  // const route = useRoute()
  const { proxy } = getCurrentInstance()
  const state = reactive({
    form: {
      memberSeq: data.memberSeq,
      option: data.option,
      msg: data.msg || '',
      pinSeq: data.pinSeq,
      isEdit: data.isEdit,
      seq: data.seq
    }
  })

  const msgInputs = e => {
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 500) {
      e.target.value = e.target.value.substr(0, 500)
    }
    state.form.msg = e.target.value
  }

  const fnSave = async () => {
    if (!isValid()) return
    const params = { ...state.form }
    let res = ''
    if (params.isEdit === 0) {
      res = await proxy.$MarketSvc.cancelRefundRequest(params)
    } else {
      res = await proxy.$MarketSvc.cancelRefundModify(params)
    }

    if (res.resultCode === '0000') {
      alert('취소/환불 신청 되었습니다.')
      callback()
      proxy.$emit('close')
    } else {
      alert(res.resultMsg)
    }
  }
  const init = () => {
    if (!proxy.$Util.isEmpty(data)) {
      proxy.$_.merge(state.form, data)
    }
  }
  const isValid = () => {
    if (state.form.option === '') {
      alert('사유를 선택해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.form.msg)) {
      alert('취소 환불 사유를 입력해주세요.')
      return false
    }
    return true
  }
  init()
  return { ...toRefs(state), fnSave, msgInputs }
}
