/** 모달 ALERT **/
import BASE from '@/components/common/modal/alert/base/index.vue'
import CONFIRM from '@/components/common/modal/alert/confirm/index.vue'
import COMMENT from '@/components/common/modal/alert/comment/index.vue'
import PARTY_HOPE from '@/components/common/modal/alert/party-hope/index.vue'
import PARTY_REFUND from '@/components/common/modal/alert/party-refund/index.vue'
import PARTY_REGENERATE from '@/components/common/modal/alert/party-regenerate/index.vue'
import PARTY_REFUND_REJECT from '@/components/common/modal/alert/party-refund-reject/index.vue'
import PARTY_EXTENSION from '@/components/common/modal/alert/party-extension/index.vue'
import REGIST_DONE from '@/components/common/modal/alert/regist-done/index.vue'
import MAIN_NOTI from '@/components/common/modal/alert/main-noti/index.vue'
import PARTY_CREATE_NOTI from '@/components/common/modal/alert/party-create-noti/index.vue'
import PARTY_ADD_INFO from '@/components/common/modal/alert/party-add-info/index.vue'
import MARKET_RE_REGIST from '@/components/common/modal/alert/market-re-regist/index.vue'
import MARKET_REFUND from '@/components/common/modal/alert/market-refund/index.vue'
import MARKET_CREATE_NOTI from '@/components/common/modal/alert/market-create-noti/index.vue'
import MARKET_ADD_INFO from '@/components/common/modal/alert/market-add-info/index.vue'
import YOUTUBE from '@/components/common/modal/alert/youtube/index.vue'
import MARKET_REFUND_REJECT from '@/components/common/modal/alert/market-refund-reject/index.vue'
import CREATED_PARTY_NOTICE_WIZZTALK from '@/components/common/modal/alert/created-party-notice-wizztalk/index.vue'
import SHARE_INFO_CHECK from '@/components/common/modal/alert/share-info-check/index.vue'
import PIN_NUMBER_ADD from '@/components/common/modal/alert/pin-number-add/index.vue'
import GOODS_ADD from '@/components/common/modal/alert/goods-add/index.vue'
import CHOOSE_PIN_NUMBER from '@/components/common/modal/alert/choose-pin-number/index.vue'
import PIN_NUMBER_VIEW from '@/components/common/modal/alert/pin-number-view/index.vue'
import SETTLE_BANK_POPUP from '@/components/common/modal/alert/settle-bank-popup/index.vue'
import REQ_OPTION_EDIT from '@/components/common/modal/alert/req-option-edit/index.vue'
import PIN_ZOOM from '@/components/common/modal/alert/pin-zoom/index.vue'
import REFUND_POLICY from '@/components/common/modal/alert/refund-policy/index.vue'
import SELLER_PROFILE from '@/components/common/modal/alert/seller-profile/index.vue'
import CHARGE_REQUEST_VIEW from '@/components/common/modal/alert/charge-request-view/index.vue'
import CUSTOM_EVENT_INPUT from '@/components/common/modal/alert/custom-event-input/index.vue'

/** 전체화면 **/
import PARTY_INFO from '@/components/common/modal/party-info/index.vue'
import SERVICE_INFO from '@/components/common/modal/service-info/index.vue'
import CROPPER from '@/components/common/modal/cropper/index.vue'
import WIZZ_TALK_INFO from '@/components/common/modal/wizz-talk-info/index.vue'

export const modalAlertNames = {
  BASE,
  CONFIRM,
  COMMENT,
  PARTY_HOPE,
  PARTY_REFUND,
  PARTY_REGENERATE,
  PARTY_REFUND_REJECT,
  REGIST_DONE,
  MAIN_NOTI,
  PARTY_CREATE_NOTI,
  MARKET_RE_REGIST,
  PARTY_ADD_INFO,
  MARKET_ADD_INFO,
  MARKET_REFUND,
  PARTY_EXTENSION,
  MARKET_CREATE_NOTI,
  MARKET_REFUND_REJECT,
  YOUTUBE,
  CREATED_PARTY_NOTICE_WIZZTALK,
  SHARE_INFO_CHECK,
  PIN_NUMBER_ADD,
  CHOOSE_PIN_NUMBER,
  PIN_NUMBER_VIEW,
  SETTLE_BANK_POPUP,
  GOODS_ADD,
  REQ_OPTION_EDIT,
  PIN_ZOOM,
  REFUND_POLICY,
  SELLER_PROFILE,
  CHARGE_REQUEST_VIEW,
  CUSTOM_EVENT_INPUT
}

export const modalNames = {
  PARTY_INFO,
  SERVICE_INFO,
  CROPPER,
  WIZZ_TALK_INFO
}
