import Util from '@/common/utils/Util'
import { merge } from 'lodash'
import { registerForm as initRegisterForm } from '@/common/form/RegisterForm'
import { detailForm as initDetailForm } from '@/common/form/DetailForm'
import FcmSvc from '@/common/service/FcmSvc'

export const state = {
  authorization: null,
  data: null,
  isAuto: null, // 0: 자동로그인, 1: 자동로그인 안함
  registerForm: {},
  detailForm: {},
  isShowMainNoti: null,
  isAutoExtensionPopup: false
}
export const getters = {
  getAuthorization(state) {
    state.authorization = state.authorization || localStorage.authorization
    return state.authorization
  },
  getData(state) {
    state.data = localStorage.data || '{}'
    return JSON.parse(state.data)
  },
  getIsAuto(state) {
    state.isAuto = state.isAuto || localStorage.isAuto
    return Number(state.isAuto)
  },
  getRegisterForm(state) {
    state.registerForm = localStorage.registerForm || '{}'
    return JSON.parse(state.registerForm)
  },
  getDetailForm(state) {
    state.detailForm = localStorage.detailForm || '{}'
    return JSON.parse(state.detailForm)
  },
  getIsShowMainNoti(state) {
    state.isShowMainNoti =
      state.isShowMainNoti || localStorage.isShowMainNoti || 1
    return Number(state.isShowMainNoti)
  },
  getIsAutoExtensionPopup(state) {
    state.isAutoExtensionPopup = localStorage.isAutoExtensionPopup || false
    return state.isAutoExtensionPopup
  }
}
export const mutations = {
  setAuthorization(state, { authorization = '' }) {
    state.authorization = authorization
    localStorage.authorization = authorization
  },
  setData(state, { data = {} }) {
    state.data = data
    localStorage.data = JSON.stringify(data)
  },
  setIsAuto(state, { isAuto = 1 }) {
    state.isAuto = isAuto
    localStorage.isAuto = isAuto
  },
  setRegisterForm(state, { registerForm = {} }) {
    state.registerForm = registerForm
    localStorage.registerForm = JSON.stringify(registerForm)
  },
  setDetailForm(state, { detailForm = {} }) {
    state.detailForm = detailForm
    localStorage.detailForm = JSON.stringify(detailForm)
  },
  storeLogout(state) {
    state.data = null
    state.authorization = null
    state.isAuto = null
    delete localStorage.authorization
    delete localStorage.data
    delete localStorage.isAuto
    delete localStorage.registerForm
    delete localStorage.detailForm
    // delete localStorage.isNotShowInfo
  },
  setIsShowMainNoti(state, { isShowMainNoti = 1 }) {
    state.isShowMainNoti = isShowMainNoti
    localStorage.isShowMainNoti = isShowMainNoti
  },
  setIsAutoExtensionPopup(state, { isAutoExtensionPopup = false }) {
    state.isAutoExtensionPopup = isAutoExtensionPopup
    localStorage.isAutoExtensionPopup = isAutoExtensionPopup
  }
}
export const actions = {
  async signIn({ commit }, { authorization, data }) {
    if (!Util.isEmpty(authorization)) {
      commit('setAuthorization', { authorization })
      /* eslint-disable */
      try {
        if (typeof android !== 'undefined') {
          android.sendFcmTokenToServer(authorization)
        }
        if (typeof window.webkit !== 'undefined') {
          if (typeof window.webkit.messageHandlers !== 'undefined' && window.webkit.messageHandlers.sendFcmTokenToServer !== 'undefined') {
            window.webkit.messageHandlers.sendFcmTokenToServer.postMessage(authorization)
          }
        }
      } catch (e) {
        console.error('sendFcmTokenToServer', e)
      }
      /* eslint-disable */
    }
    if (!Util.isEmpty(data)) {
      commit('setData', { data })
    }
    if (window.fnFirebaseMessagingGetToken) {
      const token = await window.fnFirebaseMessagingGetToken()
      console.log('fcm token :: ', token)
      if (!Util.isEmpty(token)) {
        await FcmSvc.postRegister({ token })
      }
    }
  },
  async signOut({ commit, dispatch }) {
    commit('storeLogout')
    dispatch('layout/clearKeepAlive', null, { root: true })
  },
  updateRegisterForm({ commit }, { registerForm }) {
    const payload = {
      registerForm: merge({}, initRegisterForm, registerForm)
    }
    commit('setRegisterForm', payload)
  },
  clearRegisterForm({ commit }) {
    commit('setRegisterForm', { registerForm: { ...initRegisterForm } })
  },
  updateDetailForm({ commit }, { detailForm }) {
    const payload = {
      detailForm: merge({}, initDetailForm, detailForm)
    }
    commit('setDetailForm', payload)
  },
  clearDetailForm({ commit }) {
    commit('setDetailForm', { detailForm: { ...initDetailForm } })
  },
  updateIsAuto({ commit }, payload) {
    commit('setIsAuto', payload)
  },
  updateIsShowMainNoti({ commit }, payload) {
    commit('setIsShowMainNoti', payload)
  },
  updateIsAutoExtensionPopup({ commit }, payload) {
    commit('setIsAutoExtensionPopup', payload)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
