import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = ({ data }) => {
  const router = useRouter()
  const state = reactive({
    item: { ...data }
  })
  const fnLogin = async () => {
    await router.replace({ path: '/main', query: { isRoot: 1 } })
    await router.push({ path: '/login' })
  }
  return { ...toRefs(state), fnLogin }
}
