import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    title: data.type === 'PARTY' ? '파티원' : '구매자',
    resKakao: data.resKakao || '',
    resPhone: data.resPhone || '',
    resEmail: data.resEmail || '',
    resOptions: data.resOptions || {}
  })

  const fnSave = async () => {
    if (!isValid()) return
    let resOptions = { ...state }
    delete resOptions.resOptions
    delete resOptions.title

    proxy.$emit('close')
    callback(resOptions)
  }

  const maskingResPhone = e => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
    if (!proxy.$Util.isEmpty(e.target.value) && e.target.value.length > 11) {
      e.target.value = e.target.value.substr(0, 11)
    }
    state.resPhone = String(e.target.value)
  }

  const maskingResKakao = e => {
    /* eslint-disable-next-line */
    e.target.value = e.target.value.replace(/[^A-Za-z0-9_\`\~\!\@\#\$\%\^\&\*\(\)\-\=\+\\\{\}\[\]\'\"\;\:\<\,\>\.\?\/\s]/gi, '')
    state.resKakao = e.target.value
  }

  const maskingResEmail = e => {
    /* eslint-disable-next-line */
    e.target.value = e.target.value.replace(/[^A-Za-z0-9_\`\~\!\@\#\$\%\^\&\*\(\)\-\=\+\\\{\}\[\]\'\"\;\:\<\,\>\.\?\/\s]/gi, '')
    state.resEmail = e.target.value
  }

  const isValid = () => {
    if (
      state.resOptions.resEmail &&
      !proxy.$Util.isValidEmail(state.resEmail)
    ) {
      alert('올바른 이메일 주소를 등록해주세요.')
      return false
    }

    if (
      state.resOptions.resPhone &&
      (!state.resPhone.startsWith('01') || state.resPhone.length < 10)
    ) {
      alert('올바른 휴대폰 번호를 등록해주세요.')
      return false
    }
    if (
      Object.keys(state.resOptions).some(key => proxy.$Util.isEmpty(state[key]))
    ) {
      alert(`${state.title} 요청정보를 입력해주세요.`)
      return false
    }
    return true
  }

  return {
    ...toRefs(state),
    fnSave,
    maskingResPhone,
    maskingResKakao,
    maskingResEmail
  }
}
