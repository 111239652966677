import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    shareConfirm: '0'
  })

  const fnConfirm = async () => {
    const params = { ...data }
    const res = await proxy.$PartySvc.postConfirmShare(params)
    if (res.resultCode === '0000') {
      state.shareConfirm = '1'
      callback({ shareConfirm: '1' })
      proxy.$emit('close')
    }
    alert(res.resultMsg)
  }

  return { ...toRefs(state), fnConfirm }
}
