import { computed, reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = useStore()
  const state = reactive({
    tabIndex: computed(() => getters['community/getTabIndex'])
  })

  const fnPage = async (index = 0) => {
    await router.replace({ path: proxy.$ConstCode.COMMUNITY_TYPE[index].path })
  }
  /** watch **/
  watch(
    () => route.path,
    async val => {
      const tab = proxy.$_.find(proxy.$ConstCode.COMMUNITY_TYPE, { path: val })
      if (!proxy.$Util.isEmpty(tab)) {
        await dispatch('community/updateTabIndex', { tabIndex: tab.index })
      }
    },
    { immediate: true }
  )
  return { ...toRefs(state), fnPage }
}
