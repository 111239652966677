<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div class="table_type03">
                <div class="con_ttl" style="padding-bottom: 2rem;">
                  <span>충전 요청 내역</span>
                </div>
                <dl>
                  <dt>결제수단</dt>
                  <dd>
                    {{ payType }}
                  </dd>
                </dl>
                <dl>
                  <dt>충전캐시</dt>
                  <dd>
                    {{ $Util.formatNumber2(amount, { unit: ' 원' }) }}
                  </dd>
                </dl>
                <dl>
                  <dt>이용수수료</dt>
                  <dd>
                    {{ $Util.formatNumber2(fee, { unit: ' 원' }) }}
                  </dd>
                </dl>
                <dl>
                  <dt>총 결제금액</dt>
                  <dd>
                    <span class="color">{{
                      $Util.formatNumber2(total, { unit: '' })
                    }}</span>
                    원
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnConfirm">결제진행</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-charge-request-view',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
