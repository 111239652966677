import Env from '@/common/constants/Env.json'
import EnvStage from '@/common/constants/Env_stage.json'
import EnvProd from '@/common/constants/Env_prod.json'
import Util from '@/common/utils/Util'
import { keyBy } from 'lodash'

let CODE_ENV_LIST = Env

if (process.env.NODE_ENV === 'production') {
  CODE_ENV_LIST = EnvProd
} else if (process.env.VUE_APP_MODE === 'stage') {
  CODE_ENV_LIST = EnvStage
}

const BOOLEAN_VALUE = {
  TRUE: 0,
  FALSE: 1
}

const NICE_CALL_TYPE = {
  INIT_REGIST: {
    name: '회원가입 최초인증',
    value: 0
  },
  INIT_SNS_LOGIN: {
    name: '소셜로그인 후 최초인증',
    value: 1
  },
  RE_AUTH: {
    name: '재인증',
    value: 2
  }
}

const SNS_LOGIN_TYPE = {
  KAKAO: {
    name: '카카오',
    value: 0,
    enName: 'kakao'
  },
  NAVER: {
    name: '네이버',
    value: 1,
    enName: 'naver'
  },
  GOOGLE: {
    name: '구글',
    value: 2,
    enName: 'google'
  },
  APPLE: {
    name: '애플',
    value: 3,
    enName: 'apple'
  }
}

const getLoginType = (value, key = 'value') => {
  const temp = keyBy(SNS_LOGIN_TYPE, key)
  return temp[value]
}

const CATEGORY_VALUE = {
  1000: { name: '구독', id: '1000' },
  2000: { name: '마켓', id: '2000' },
  3000: { name: '커뮤니티', id: '3000' }
}

const CATEGORY2_VALUE = [
  {
    name: '넷플릭스',
    color: '#E21221',
    id: '1001',
    text: '넷플릭스',
    enText: 'netflix',
    enabledImg: '01_net.png',
    disabledImg: 'nn.png'
  },
  {
    name: '왓챠플레이',
    color: '#FF0558',
    id: '1002',
    text: '왓챠플레이',
    enText: 'watcha',
    enabledImg: '03_watcha.png',
    disabledImg: 'wc.png'
  },
  {
    name: '웨이브',
    color: '#1312C1',
    id: '1003',
    text: '웨이브',
    enText: 'wavve',
    enabledImg: 'WW.png',
    disabledImg: 'wa.png'
  },
  {
    name: '유튜브프리미엄',
    color: '#ff0000',
    id: '1004',
    text: '유튜브프리미엄',
    enText: 'youtube',
    enabledImg: 'yy.png',
    disabledImg: 'yy-1.png'
  },
  {
    name: '티빙',
    color: '#9000FF',
    id: '1005',
    text: '티빙',
    enText: 'tving',
    enabledImg: 'tving.png',
    disabledImg: 'tving-1.png'
  },
  {
    name: '라프텔',
    color: '#826CFF',
    id: '1006',
    text: '라프텔',
    enText: 'laftel',
    enabledImg: 'laftel.png',
    disabledImg: ''
  },
  {
    name: '디즈니+',
    color: '#2F1F54',
    id: '1007',
    text: '디즈니+',
    enText: 'disney',
    enabledImg: 'disney.svg',
    disabledImg: ''
  },
  {
    name: '기타',
    color: '#A0A1A2',
    id: '1100',
    text: '기타',
    enText: 'etc',
    enabledImg: '',
    disabledImg: ''
  },
  {
    name: '마켓',
    color: '#003471',
    fontColor: '#fff',
    id: '2000',
    text: '마켓',
    enText: 'market',
    enabledImg: '',
    disabledImg: ''
  },
  {
    name: '상품권',
    color: '#003471',
    fontColor: '#fff',
    id: '2001',
    text: '상품권',
    enText: 'voucher',
    enabledImg: '',
    disabledImg: ''
  },
  {
    name: '티켓',
    color: '#003471',
    fontColor: '#fff',
    id: '2002',
    text: '티켓',
    enText: 'ticket',
    enabledImg: '',
    disabledImg: ''
  },
  {
    name: '나눔',
    color: '#003471',
    fontColor: '#fff',
    id: '2003',
    text: '나눔',
    enText: 'ticket',
    enabledImg: '',
    disabledImg: ''
  },
  {
    name: '기타',
    color: '#A0A1A2',
    fontColor: '#fff',
    id: '2100',
    text: '기타',
    enText: 'etc',
    enabledImg: '',
    disabledImg: ''
  }
]

const CATEGORY3_VALUE = [
  {
    name: 'ALL',
    id: '2000',
    text: 'ALL',
    enabledImg: ''
  },
  {
    name: '상품권',
    id: '2001',
    text: '상품권',
    enabledImg: 'btn_tab_prs.svg'
  },
  {
    name: '티켓',
    id: '2002',
    text: '티켓',
    enabledImg: 'btn_tab_ticket.svg'
  },
  {
    name: '나눔',
    id: '2003',
    text: '나눔',
    enabledImg: ''
  },
  {
    name: 'etc',
    id: '2100',
    text: 'etc',
    enabledImg: ''
  }
]

const COMMUNITY_TYPE = [
  { name: '위즈피디아', index: 0, path: '/community/wizzpedia/search' },
  {
    name: '평점',
    index: 1,
    path: '/community/party-score'
  }
]

const PAY_TYPE = {
  1: { name: '신용카드', value: 1 },
  2: { name: '위즈캐시', value: 2 }
}

const MY_PAGE_TABS = [
  { name: '홈', index: 0, path: '/my-page/home' },
  // {
  //   name: '알림',
  //   index: 1,
  //   path: '/my-page/party-noti'
  // },
  { name: '위즈톡', index: 1, path: '/my-page/wizz-talk' },
  {
    name: '구독 내역',
    index: 2,
    path: '/my-page/joined-party',
    subPath1: '/my-page/created-party'
  },
  { name: '마켓 내역', index: 3, path: '/my-page/market' },
  { name: '평점 관리', index: 4, path: '/my-page/score' },
  { name: '정산 관리', index: 5, path: '/my-page/adjustment' },
  { name: '고객 센터', index: 6, path: '/cs/notice' }
  // { name: '캐시/포인트', index: 6, path: '/my-page/cash-point' },
  // { name: '패널티 게시판', index: 7, path: '/my-page/penalty' }
]

const CS_TABS = [
  { name: '공지사항', index: 0, path: '/cs/notice', isUserOnly: false },
  { name: 'FAQ', index: 1, path: '/cs/faq', isUserOnly: false },
  {
    name: '1:1 문의/신고',
    index: 2,
    path: '/cs/my-question',
    isUserOnly: true
  },
  {
    name: '판매자신고',
    index: 3,
    path: '/cs/report',
    isUserOnly: true
  },
  { name: '약관 및 정보보호', index: 4, path: '/cs/terms', isUserOnly: false }
]

const PARTY_CONFLICT_STATUS = [
  { name: '진행중', value: 0 },
  { name: '승인', value: 1 },
  { name: '반려', value: 2 },
  { name: '분쟁취소', value: 3 }
]

const CASH_TYPE = [
  { name: '판매적립', value: 'SELL', isPlus: true },
  { name: '구매소진', value: 'BUY', isPlus: false },
  { name: '환불적립', value: 'REFUND', isPlus: true },
  { name: '출금소진', value: 'WITHDRAW', isPlus: false },
  { name: '기타적립', value: 'ETC_GAIN', isPlus: true },
  { name: '기타소진', value: 'ETC_USING', isPlus: false },
  { name: '충전', value: 'CHARGE', isPlus: true },
  { name: '충전취소', value: 'CHARGE_CANCEL', isPlus: false }
]

const LEVEL_TYPE = {
  0: { name: '미인증', value: 0 },
  1: { name: '일반회원', value: 1 },
  2: { name: '우수회원', value: 2 }
}

const USER_AUTH_TYPE = {
  EMAIL: {
    name: '이메일 인증 완료',
    value: 1
  },
  PHONE: {
    name: '휴대폰 인증완료',
    value: 2
  },
  BANK: {
    name: '계좌 인증완료',
    value: 3
  },
  PHONE_AND_BANK: {
    name: '계좌, 휴대폰 인증완료',
    value: 4
  }
}

const PRODUCT_TYPE = {
  PARTY: {
    name: '구독 상품',
    value: 'PARTY'
  },
  MARKET: {
    name: '마켓 상품',
    value: 'MARKET'
  },
  CHARGE: {
    name: '충전',
    value: 'CHARGE'
  }
}

const REPORT_TYPE = [
  {
    name: '개인 마켓[구독] 광고 및 부적합 상품 판매',
    value: '0'
  },
  {
    name: '직거래 유도',
    value: '1'
  },
  {
    name: '욕설 및 언어 폭력',
    value: '2'
  },
  {
    name: '판매가격 허위 기재',
    value: '3'
  },
  {
    name: '상품[계정] 미발송',
    value: '4'
  },
  {
    name: '상품[계정] 정보 불일치',
    value: '5'
  },
  {
    name: '거래/환불 분쟁',
    value: '6'
  },
  {
    name: '기타',
    value: '7'
  }
]

const JOINED_PARTY_CONDITION = [
  {
    name: '진행중',
    status: '0'
  },
  {
    name: '일시정지',
    status: '1'
  },
  {
    name: '취소/환불',
    status: '2'
  },
  {
    name: '종료',
    status: '3'
  }
]

const CREATED_PARTY_CONDITION = [
  {
    name: '모집중',
    status: '0'
  },
  {
    name: '모집완료',
    status: '1'
  },
  {
    name: '자체마감',
    status: '2'
  },
  {
    name: '종료',
    status: '3'
  }
]

const SALE_MARKET_CONDITION = [
  {
    name: '판매중',
    status: '0'
  },
  {
    name: '거래완료',
    status: '1'
  },
  {
    name: '자체마감',
    status: '2'
  }
]

const BUY_MARKET_CONDITION = [
  {
    name: '거래중',
    status: '0'
  },
  {
    name: '환불완료',
    status: '1'
  },
  {
    name: '거래완료',
    status: '2'
  },
  {
    name: '사용완료',
    status: '3'
  }
]

const ConstCode = function() {
  return {
    CODE_ENV_LIST,
    BOOLEAN_VALUE,
    NICE_CALL_TYPE,
    SNS_LOGIN_TYPE,
    CATEGORY_VALUE,
    CATEGORY2_VALUE,
    CATEGORY3_VALUE,
    COMMUNITY_TYPE,
    PAY_TYPE,
    MY_PAGE_TABS,
    CS_TABS,
    PARTY_CONFLICT_STATUS,
    CASH_TYPE,
    LEVEL_TYPE,
    USER_AUTH_TYPE,
    PRODUCT_TYPE,
    REPORT_TYPE,
    JOINED_PARTY_CONDITION,
    CREATED_PARTY_CONDITION,
    SALE_MARKET_CONDITION,
    BUY_MARKET_CONDITION,
    getLoginType,
    getImagePath: function(path = '') {
      if (Util.isEmpty(path)) return ''
      return `${this.CODE_ENV_LIST.FILE_SERVER_URL}${path}`
    },
    getFilePath: function(path = '') {
      if (Util.isEmpty(path)) return ''
      return `${this.CODE_ENV_LIST.FILE_SERVER_URL}${path}`
    },
    getCategoryById: function(categoryId) {
      return keyBy(this.CATEGORY2_VALUE, 'id')[categoryId]
    },
    getCategoryByText: function(categoryText) {
      return keyBy(this.CATEGORY2_VALUE, 'enText')[categoryText]
    },
    getCategoryByName: function(categoryName) {
      return keyBy(this.CATEGORY2_VALUE, 'name')[categoryName]
    }
  }
}

// // 본인인증
// let windowObj
// let vmFunction
// let vmFunctionName
// window.addEventListener('message', function({ data }) {
//   if (!Util.isEmpty(data.source) && data.source === vmFunctionName) {
//     // 본인인증 콜백 message 처리
//     if (windowObj) {
//       windowObj.close()
//     }
//     vmFunction(data.params)
//   }
// })

export default ConstCode()
