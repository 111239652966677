<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner_profile">
        <div class="modal_profile" style="margin-bottom: 1rem;">
          <div class="modal_btn">
            <div class="profile">
              <div
                class="prof"
                :class="[
                  {
                    off: userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                  },
                  {
                    vip: sellerLv >= 3
                  },
                  {
                    nonebg:
                      !$Util.isEmpty(profile) && profile.indexOf('/null') < 0
                  }
                ]"
              >
                <!-- 프로필이미지 지정시 nonebg 추가 -->
                <div class="img_area">
                  <img
                    :src="profile"
                    alt=""
                    v-if="
                      !$Util.isEmpty(profile) && profile.indexOf('/null') < 0
                    "
                  />
                </div>
              </div>
              <div class="nickname">{{ userNm }}</div>
            </div>
          </div>
        </div>
        <div class="modal_profile">
          <div class="modal_btn">
            <div v-if="!$Util.isEmpty(userData)" class="btn_wrap pb">
              <button @click="fnWizztalk" class="btn bo">위즈톡 보내기</button>
            </div>
            <div class="btn_wrap pb">
              <button @click="fnTrading" class="btn bo">
                거래중인 상품 보기
              </button>
            </div>
            <div v-if="!$Util.isEmpty(userData)" class="btn_wrap pb">
              <button @click="fnReport" class="btn bo">판매자 신고하기</button>
            </div>
          </div>
        </div>
        <div class="close">
          <button @click="() => $emit('close')" class="closebtn"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-seller-profile',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>
