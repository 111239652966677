<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con" v-if="confirm == '0'">
            <div>
              <div class="modal_txt">
                <div>
                  <div class="con_ttl">
                    <span
                      >{{
                        type == '0' ? '바코드(PIN번호)' : '추가인증코드'
                      }}
                      정보를 확인 하시겠습니까?</span
                    >
                  </div>
                  <div class="hint">
                    {{ type == '0' ? '바코드(PIN번호)' : '추가인증코드' }} 정보
                    노출 이후에는 "단순 변심에 의한 환불요청이 불가능합니다.
                    (판매자 과실에 의한 환불요청 가능)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_con" v-else>
            <div>
              <div class="modal_txt">
                <div>
                  <div class="grayci">
                    <img src="@/assets/static/images/gray_ci.svg" />
                  </div>
                  <div class="barcodemodal" style="margin-top: 1rem;">
                    <dl v-if="thumbnail">
                      <div class="market_thumb">
                        <div class="img_area">
                          <img :src="thumbnail" alt="" />
                        </div>
                      </div>
                    </dl>
                    <dl>
                      <dt>{{ data.title }}</dt>
                      <dd>
                        <span class="gray">사용기한 : </span>{{ data.useDt }}
                      </dd>
                      <dd>
                        <span class="gray">인증 및 기타번호 : </span>{{ exNum }}
                      </dd>
                    </dl>
                    <dl
                      v-if="!(data.status == '4' || data.status == '3') && pin"
                    >
                      <dd style="text-align: center;">
                        <VueBarcode
                          v-if="pin"
                          :value="pin"
                          :options="{ width: 1 }"
                          @click="fnModalPinZoom"
                        />
                      </dd>
                      <dd class="hint">
                        * 바코드 인식이 어려울 경우 PIN번호를 활용해 주세요.
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn" v-if="confirm == '0'">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnConfirm">확인</button>
            </div>
          </div>
          <div class="modal_btn" v-else>
            <div class="btn_wrap">
              <button class="btn" @click="$emit('close')">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'
import VueBarcode from '@chenfengyuan/vue-barcode'

export default {
  name: 'modal-alert-pin-number-view',
  components: { VueBarcode },
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
