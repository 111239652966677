<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>환불 요청</span>
                </div>
                <div>
                  <div class="table_type02" style="margin-top: 1rem;">
                    <dl>
                      <dt>구매 내역</dt>
                      <dd>
                        <span>{{ data.useDt }}</span>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_in">
                          <div class="inline">
                            <label v-if="data.simpleChangeMind" class="radio">
                              <input
                                type="radio"
                                name="reason"
                                value="0"
                                v-model="form.option"
                              />
                              <span class="label"><span>단순 변심</span></span>
                            </label>
                            <label class="radio">
                              <input
                                type="radio"
                                name="reason"
                                value="1"
                                v-model="form.option"
                              />
                              <span class="label"
                                ><span>판매자 과실</span></span
                              >
                            </label>
                          </div>
                        </div>
                      </li>
                      <li class="form_li">
                        <div class="form_in">
                          <label>
                            <span
                              v-if="!form.msg"
                              style="position: absolute; padding: 0.5rem 1rem; font-size: 1.4rem; color: #999;"
                            >
                              취소/환불 사유를 입력해주세요.<br />(500자 이내)
                            </span>
                            <textarea
                              @input="msgInputs"
                              :value="form.msg"
                              maxlength="500"
                            ></textarea>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-market-refund',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
