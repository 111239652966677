<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <!--<div class="con_ttl center color">
                    <span><br>공지사항<br><br></span>
                </div>-->
                <div class="center">
                  파티 생성을 위해 다음 필수 정보를<br />
                  개인 정보 변경에서 확인 및 수정해 주세요.<br /><br />
                  <b>· 휴대폰 본인인증</b> &nbsp;&nbsp;&nbsp;
                  <b>· 출금 계좌 입력</b>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn" @click="fnMove">
                인증 및 개인 정보 변경 이동
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-create-noti',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
