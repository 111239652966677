import router from '@/router'
import store from '@/store'
import { fnModalAlert } from '@/components/common/modal/alert'
import ConstCode from '@/common/constants/ConstCode'
import Util from '@/common/utils/Util'
import MyPageSvc from '@/common/service/MyPageSvc'
import UserSvc from '@/common/service/UserSvc'
import PARTY_CREATE_NOTI from '@/components/common/modal/alert/party-create-noti/index.vue'
import MARKET_CREATE_NOTI from '@/components/common/modal/alert/market-create-noti/index.vue'
import { getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export const CommonFunction = props => {
  const { proxy } = getCurrentInstance()
  const { dispatch } = useStore()
  const route = useRoute()
  const fnCheckUserAuth = async () => {
    let havePwYn = ConstCode.BOOLEAN_VALUE.FALSE
    const res = await MyPageSvc.postMyUserInfoHavePwYn()
    if (res.resultCode === '0000') {
      havePwYn = Number(res.havePwYn)
    } else {
      alert(res.resultMsg)
    }
    return havePwYn
  }
  const fnMyInfo = async (isAccount = -1) => {
    const userData = store.getters['user/getData']
    // 비밀번호 설정 유무
    const havePwYn = await fnCheckUserAuth()
    const isAuth =
      !Util.isEmpty(userData.authType) && Number(userData.authType) > 1

    if (isAuth && havePwYn === ConstCode.BOOLEAN_VALUE.TRUE) {
      // 비밀번호 체크
      if (isAccount === 1) {
        await router.push({ path: '/my-info/phone-auth' })
      } else {
        await router.push({ path: '/my-info/password' })
      }
    } else if (isAuth && havePwYn === ConstCode.BOOLEAN_VALUE.FALSE) {
      // 인증번호 발송
      await router.push({ path: '/my-info/phone-auth' })
    } else {
      // 본인인증 페이지
      try {
        const params = {}
        params.callType = ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
        params.rtnUrl = props.rtnUrl
        params.failRtnUrl = props.failRtnUrl
        const res = await UserSvc.postAuthNiceAuthPop(params)
        if (Util.isEmpty(res.niceData)) {
          alert(res.niceMessage)
          return
        }
        props.niceForm.encodeData = res.niceData
        props.niceForm.callType = ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      } catch (err) {
        console.error(err)
      }
    }
  }
  const fnLogout = async () => {
    if (!confirm('로그아웃하시겠습니까?')) return
    const userData = store.getters['user/getData']
    const params = {}
    if (Util.isEmpty(userData.loginType)) {
      params.id = userData.userId
      // await proxy.$FcmSvc.postUnregister()
      await UserSvc.postLogout(params)
    } else {
      await store.dispatch('user/signOut')
    }
    location.replace('/')
  }
  const fnUpdateAuthType = () => {
    const userData = store.getters['user/getData']
    const data = { ...userData }
    data.authType = 2
    store.commit('user/setData', { data })
  }
  const fnWrite = async beforeEnter => {
    const userData = store.getters['user/getData']
    if (Util.isEmpty(userData)) {
      await router.push({ path: '/login' })
      return false
    }
    if (
      !Util.isEmpty(userData) &&
      (Util.isEmpty(userData.authType) || Number(userData.authType) < 4)
    ) {
      await fnModalPartyCreateNoti()
      return false
    }
    if (typeof beforeEnter === 'function') {
      await beforeEnter()
    }
    await router.push({ path: '/party/write' })
  }

  const fnMarketWrite = async beforeEnter => {
    const userData = store.getters['user/getData']
    if (Util.isEmpty(userData)) {
      await router.push({ path: '/login' })
      return false
    }
    if (
      !Util.isEmpty(userData) &&
      (Util.isEmpty(userData.authType) || Number(userData.authType) < 4)
    ) {
      await fnModalMarketCreateNoti()
      return false
    }

    if (typeof beforeEnter === 'function') {
      await beforeEnter()
    }
    await router.push({ path: '/market/write' })
  }

  const fnModalMarketCreateNoti = async () => {
    const payload = {
      component: MARKET_CREATE_NOTI,
      data: {},
      callback: async () => {
        await fnMyInfo()
      }
    }
    await fnModalAlert(payload)
  }

  const fnModalPartyCreateNoti = async () => {
    const payload = {
      component: PARTY_CREATE_NOTI,
      data: {},
      callback: async () => {
        await fnMyInfo()
      }
    }
    await fnModalAlert(payload)
  }

  const switchTab = async n => {
    if (n < 3) {
      const item = store.getters['nav/getCategoryList'][n]
      await dispatch('nav/updateOnDepth1Item', { onDepth1Item: item })
      if (item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['1000'].id) {
        // ALL 초기화
        await dispatch('nav/updateOnDepth2Item', {
          onDepth2Item: {
            categoryID: '',
            categoryName: 'ALL',
            categoryOrder: '-1',
            depth: 2,
            upperCategoryID: '0000',
            url: ''
          }
        })
        await router.push({ path: '/party/list', query: { activeTab: n } })
      } else if (
        item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['2000'].id
      ) {
        // await dispatch('nav/updateOnDepth2Item', {
        //   onDepth2Item: {}
        // })
        await router.push({ path: '/market/list', query: { activeTab: n } })
      } else if (
        item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['3000'].id
      ) {
        await router.push({ path: '/community/wizzpedia/search' })
      }
    }
    props.activeTab = n
  }

  const start = e => {
    setTouchPos(e, 'start')
  }
  const move = e => {
    setTouchPos(e, 'move')
  }
  const end = async e => {
    setTouchPos(e, 'end')
    const userData = store.getters['user/getData']
    // TODO 정상동작이 안돼서 임시적으로 처리. 2차개발 수정필요
    let activeTab = Number(route.query.activeTab)
    if (route.path.match('/community/')) {
      activeTab = 2
    } else if (route.path.match('/my-page/')) {
      activeTab = 3
    }
    let maxLength = 3
    if (!Util.isEmpty(userData)) {
      maxLength = 4
    }
    console.log('activeTab {}', activeTab)
    let dx = props.touch.sx - props.touch.ex,
      dy = props.touch.sy - props.touch.ey,
      dt = props.touch.et - props.touch.st,
      dir = Math.sign(dx),
      nTab = activeTab + dir,
      vMove = Math.abs(dx) / Math.abs(dy) < Math.sqrt(4)
    nTab = nTab >= 0 && nTab < maxLength ? nTab : null
    if (Math.abs(dx) > 10 && nTab !== null && !vMove && dt < 300) {
      await switchTab(Number(nTab))
    }
  }

  const setTouchPos = (e, event) => {
    let ev = e.changedTouches ? e.changedTouches[0] : e
    if (event === 'start') {
      props.touch.sx = ev.clientX
      props.touch.sy = ev.clientY
      props.touch.st = Date.now()
    } else {
      props.touch.ex = ev.clientX
      props.touch.ey = ev.clientY
      props.touch.et = Date.now()
    }
  }

  const copyCliboard = (value, name = '데이터') => {
    navigator.clipboard.writeText(value).then(
      () => {
        alert(`${name}가 복사 되었습니다.`)
      },
      err => {
        console.log(err)
        alert('다시 한번 시도해주세요.')
      }
    )
  }

  return {
    fnCheckUserAuth,
    fnMyInfo,
    fnLogout,
    fnUpdateAuthType,
    fnWrite,
    switchTab,
    start,
    move,
    end,
    fnMarketWrite,
    copyCliboard
  }
}
