<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl center">
                  <span
                    >{{
                      data.isExtensionConfirm === 1
                        ? '파티 자동 연장 확인'
                        : '파티가 연장되었습니다.'
                    }}<br /><br
                  /></span>
                </div>
                <div class="party_list_li" style="height: auto;">
                  <!--210820 style 추가-->
                  <div class="party_tag center big">
                    <!--'중앙: center','큰사이즈: big'-->
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        :class="{ sl: data.acntTransferYn === 'N' }"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            party.categoryId
                          ).color
                        }"
                      >
                        {{ party.categoryNm }}
                      </div>
                      <!--210713 sl 추가-->
                    </div>
                    <div class="right">
                      <!--<div class="pt_tag new"></div>-->
                    </div>
                  </div>
                  <div class="party_ttl">
                    {{ party.title }}
                  </div>
                  <div class="party_con" style="position: unset;">
                    <!--210820 style 추가-->
                    <div class="left">
                      <div class="party_info">
                        <dl>
                          <dt>시작일 :</dt>
                          <dd>
                            {{ $dayjs(party.regDt).format('YYYY-MM-DD') }}
                          </dd>
                        </dl>
                        <dl>
                          <dt>종료일 :</dt>
                          <dd>
                            {{ $dayjs(party.useDt).format('YYYY-MM-DD') }} (일
                            평균 {{ party.dailyPrice }}원)
                          </dd>
                        </dl>
                        <!--                        <dl>
                          <dt>파티신청 :</dt>
                          <dd>{{ party.totalMemberCnt }}명</dd>
                        </dl>-->
                        <dl>
                          <dt>파티 참여가 :</dt>
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(totalPrice, { unit: '' })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                    <!--                    <div>
                      <span class="color">{{ diffDay }}</span
                      >일 단위로 자동 연장 (일 평균 {{ party.dailyPrice }}원)
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnCancel">
                취소
              </button>
              <button class="btn" @click="fnExtension">
                {{ party.isExtensionConfirm === 1 ? '파티연장' : '결제하기' }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-extension',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
