import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = ({ data }) => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    memberSeq: data.memberSeq,
    profile: data.profile,
    userNm: data.userNm,
    sellerLv: data.sellerLv,
    productNo: data.productNo,
    productType: data.productType,
    userData: data.userData,
    userLvNm: data.userLvNm
  })

  const fnWizztalk = async () => {
    if (state.memberSeq) {
      if (state.productType === 'PARTY') {
        const params = {
          partyNo: state.productNo
        }
        const res = await proxy.$ChatSvc.postGetChatroomId(params)
        if (res.resultCode === '0000') {
          params.chatroomId = res.chatroomId
          router.push({
            name: 'wizz-talk-index',
            query: params
          })
        } else {
          alert(res.resultMsg)
        }
      } else if (state.productType === 'MARKET') {
        const params = {
          marketNo: state.productNo,
          memberSeq: state.memberSeq
        }
        const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
        if (res.resultCode === '0000') {
          params.chatroomId = res.chatroomId
          router.push({
            name: 'market-wizz-talk-index',
            query: params
          })
        } else {
          alert(res.resultMsg)
        }
      }
    } else {
      if (state.productType === 'PARTY') {
        const params = {
          type: 1,
          partyNo: state.productNo
        }
        const res = await proxy.$ChatSvc.postGetChatroomId(params)
        if (res.resultCode === '0000') {
          params.chatroomId = res.chatroomId
          router.push({
            name: 'wizz-talk-index',
            query: params
          })
        } else {
          alert(res.resultMsg)
        }
      } else if (state.productType === 'MARKET') {
        const params = {
          marketNo: state.productNo,
          type: 1
        }
        const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
        if (res.resultCode === '0000') {
          params.chatroomId = res.chatroomId
          router.push({
            name: 'market-wizz-talk-index',
            query: params
          })
        } else {
          alert(res.resultMsg)
        }
      }
    }
  }

  const fnTrading = () => {
    router.push({
      name: 'trading-index',
      params: {
        type: state.productType,
        productNo: state.productNo
      }
    })
  }

  const fnReport = () => {
    router.push({
      name: 'cs-report-write-index',
      query: {
        type: state.productType,
        productNo: state.productNo,
        userNm: state.userNm
      }
    })
  }

  return { ...toRefs(state), fnTrading, fnWizztalk, fnReport }
}
