<template>
  <div class="modal_dim on" @click.stop="fnClose">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal" style="max-width: 100rem;">
          <div class="modal_con" style="background: none;">
            <iframe
              id="youtube_modal_container"
              :src="
                `https://www.youtube.com/embed/${data.code}?playerapiid=ytplayer`
              "
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'modal-alert-youtube-index',
  props: {
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
