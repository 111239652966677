import { markRaw } from 'vue';

const routeState = {
  isHeader: false,
  isNav: false,
  isFooter: false,
  isBack: false,
  isLogin: false
}

export const state = {
  isLoading: false,
  modalAlertComponents: [],
  modalComponents: [],
  routeLayout: {
    ...routeState
  },
  keepAlive: [
    'party-list-index',
    'community-wizzpedia-search-index',
    'my-page-index'
  ]
}
export const getters = {
  getIsLoading: state => {
    return state.isLoading
  },
  getModalAlertComponents: state => {
    return state.modalAlertComponents
  },
  getModalComponents: state => {
    return state.modalComponents
  },
  getRouteLayout: state => {
    return state.routeLayout
  },
  getKeepAlive: state => {
    return state.keepAlive
  }
}
export const mutations = {
  setIsLoading(state, { isLoading }) {
    state.isLoading = isLoading
  },
  setModalAlertComponents(state, { modalAlertComponents }) {
    state.modalAlertComponents = modalAlertComponents
  },
  setModalComponents(state, { modalComponents }) {
    state.modalComponents = modalComponents
  },
  setRouteLayout(
    state,
    {
      isHeader = undefined,
      isBack = undefined,
      isNav = undefined,
      isFooter = undefined,
      isLogin = undefined
    }
  ) {
    if (isHeader !== undefined) state.routeLayout.isHeader = isHeader
    if (isBack !== undefined) state.routeLayout.isBack = isBack
    if (isNav !== undefined) state.routeLayout.isNav = isNav
    if (isFooter !== undefined) state.routeLayout.isFooter = isFooter
    if (isLogin !== undefined) state.routeLayout.isLogin = isLogin
  },
  setKeepAlive(state, { keepAlive }) {
    state.keepAlive = keepAlive
  }
}
export const actions = {
  updateIsLoading({ commit }, { isLoading = false }) {
    commit('setIsLoading', { isLoading })
  },
  pushModalAlertComponent({ state, commit }, payload) {
    state.modalAlertComponents.push(markRaw(payload))
    commit('setModalAlertComponents', {
      modalAlertComponents: markRaw(state.modalAlertComponents)
    })
  },
  removeAllModalAlertComponent({ commit }) {
    commit('setModalAlertComponents', {
      modalAlertComponents: []
    })
  },
  closeModalAlertComponent({ state, commit }, { index = 0 }) {
    state.modalAlertComponents.splice(index, 1)
    commit('setModalAlertComponents', {
      modalAlertComponents: state.modalAlertComponents
    })
  },
  pushModalComponent({ state, commit }, payload) {
    state.modalComponents.push(payload)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  removeAllModalComponent({ commit }) {
    commit('setModalComponents', {
      modalComponents: []
    })
  },
  closeModalComponent({ state, commit }, { index = 0 }) {
    state.modalComponents.splice(index, 1)
    commit('setModalComponents', {
      modalComponents: state.modalComponents
    })
  },
  updateRouteLayout({ commit }, payload) {
    commit('setRouteLayout', payload)
  },
  updateKeepAlive({ commit }, payload) {
    commit('setKeepAlive', payload)
  },
  clearKeepAlive({ commit }) {
    commit('setKeepAlive', { keepAlive: [] })
    setTimeout(() => {
      commit('setKeepAlive', {
        keepAlive: [
          'party-list-index',
          'community-wizzpedia-search-index',
          'my-page-index'
        ]
      })
    }, 1000)
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
