import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/assets/static/css/main.scss'
import Util from '@/common/utils/Util'
import ConstCode from '@/common/constants/ConstCode'
import {
  modalAlertNames,
  modalNames
} from './components/common/globalComponent'
import dayjs from 'dayjs'
import lodash from 'lodash'
import { DatePicker } from 'v-calendar'

import Nice from '@/components/common/nice'

import CustomerSvc from '@/common/service/CustomerSvc'
import UserSvc from '@/common/service/UserSvc'
import CommonSvc from '@/common/service/CommonSvc'
import MainSvc from '@/common/service/MainSvc'
import PartySvc from '@/common/service/PartySvc'
import WizzpediaSvc from '@/common/service/WizzpediaSvc'
import ChatSvc from '@/common/service/ChatSvc'
import CashSvc from '@/common/service/CashSvc'
import MyPageSvc from '@/common/service/MyPageSvc'
import FcmSvc from '@/common/service/FcmSvc'
import MarketSvc from '@/common/service/MarketSvc'

import VueScrollTo from 'vue-scrollto'

/** swiper **/
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import 'swiper/components/scrollbar/scrollbar.scss'
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
/** swiper **/

import { ObserveVisibility } from 'vue-observe-visibility'

import ComponentObserveVisibility from '@/components/common/observe-visibility'

const app = createApp(App)

app.config.globalProperties.$Util = Util
app.config.globalProperties.$ConstCode = ConstCode
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$_ = lodash

app.config.globalProperties.$CustomerSvc = CustomerSvc
app.config.globalProperties.$UserSvc = UserSvc
app.config.globalProperties.$CommonSvc = CommonSvc
app.config.globalProperties.$MainSvc = MainSvc
app.config.globalProperties.$PartySvc = PartySvc
app.config.globalProperties.$WizzpediaSvc = WizzpediaSvc
app.config.globalProperties.$ChatSvc = ChatSvc
app.config.globalProperties.$CashSvc = CashSvc
app.config.globalProperties.$MyPageSvc = MyPageSvc
app.config.globalProperties.$FcmSvc = FcmSvc
app.config.globalProperties.$MarketSvc = MarketSvc

app.config.globalProperties.$modalAlertNames = modalAlertNames
app.config.globalProperties.$modalNames = modalNames

// 전역 컴포넌트 등록
for (let key in modalAlertNames) {
  app.component(modalAlertNames[key].name, modalAlertNames[key])
}
for (let key in modalNames) {
  app.component(modalNames[key].name, modalNames[key])
}

app.component(Swiper.name, Swiper)
app.component(SwiperSlide.name, SwiperSlide)

app.component('DatePicker', DatePicker)

app.component('observe-visible', ComponentObserveVisibility)
app.component('nice', Nice)

app.directive('observe-visibility', {
  beforeMount: (el, binding, vnode) => {
    vnode.context = binding.instance
    ObserveVisibility.bind(el, binding, vnode)
  },
  updated: ObserveVisibility.update,
  unmounted: ObserveVisibility.unbind
})

app
  .use(VueScrollTo, {
    container: 'body',
    duration: 500,
    easing: 'ease',
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
  })
  .use(store)
  .use(router)
  .mount('body')
