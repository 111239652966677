import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class CashSvc extends ServiceExec {
  /**
   * 파티 결제 – 결제(카드)
   * @param params
   * @returns {*}
   */
  postSettlePayload(params = {}) {
    return this.post(`/api/cash/settlePayload`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 결제 – 결제(캐시)
   * @param params
   * @returns {*}
   */
  postPartyPayment(params = {}) {
    return this.post(`/api/cash/partyPayment`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 결제 완료 페이지
   * @param params
   * @returns {*}
   */
  postPartyPaymentResult(params = {}) {
    return this.post(`/api/cash/partyPaymentResult`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 결제 완료 페이지
   * @param params
   * @returns {*}
   */
  postMarketPaymentResult(params = {}) {
    return this.post(`/api/cash/marketPaymentResult`, params).then(response => {
      return response.data
    })
  }

  /**
   * 참여한 파티 결제 승인
   * @param params
   * @returns {*}
   */
  postPartyPaymentApproval(params = {}) {
    return this.post(`/api/cash/partyPaymentApproval`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 현금영수증 자진발급 신청
   * @param params
   * @returns {*}
   */
  postRequestCashReceipt(params = {}) {
    return this.post(`/api/cash/requestCashReceipt`, params).then(response => {
      return response.data
    })
  }

  /**
   * 캐시 결재
   * @param params
   * @returns {*}
   */
  postCashPayment(params = {}) {
    return this.post(`/api/cash/cashPayment`, params).then(response => {
      return response.data
    })
  }

  /**
   * 구매한 마켓 결제 승인
   * @param params
   * @returns {*}
   */
  postMarketPaymentApproval(params = {}) {
    return this.post(`/api/cash/marketPaymentApproval`, params).then(
      response => {
        return response.data
      }
    )
  }

  // 캐시충전 카드 결제
  postCashSettlePayLoad(params = {}) {
    return this.post(`/api/cash/settlePayload`, params).then(res => res.data)
  }

  // 2-6-5 캐시 충전 결제 완료 페이지
  postCashCargeResult(params = {}) {
    return this.post(`/api/cash/cashChargeResult`, params).then(res => res.data)
  }

  // 캐시충전 정보
  postCashChargeInfo() {
    return this.post(`/api/cash/cashChargeInfo`).then(res => res.data)
  }

  // 캐시 충전 결제 취소 페이지
  postCashCargeCancelResult(params = {}) {
    return this.post(`/api/cash/cashChargeCancelResult`, params).then(
      res => res.data
    )
  }
}
export default new CashSvc()
