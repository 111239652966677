<template>
  <div class="pop_center" v-show-modal-alert>
    <!--'팝업 나타날 때: on 추가'-->
    <div class="pop_table">
      <div class="pop_table_cell">
        <div class="pop_con">
          <div class="pop_header">
            <h1>{{ layout.title }}</h1>
          </div>
          <div class="pop_main">
            <div class="pop_main_inner">
              <p v-html="layout.contents"></p>
            </div>
          </div>
          <div class="pop_btm half">
            <button class="dis" @click="fnCallBackCancel">
              {{ cancelText }}
            </button>
            <button @click="fnCallBackDone">{{ doneText }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue'

export default {
  name: 'modal-alert-confirm',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    layout: {
      type: [Object],
      default: () => {}
    },
    data: {
      type: [Object],
      default: () => {}
    },
    doneText: {
      type: [String],
      default: '확인'
    },
    cancelText: {
      type: [String],
      default: '취소'
    },
    callBackDone: {
      type: [Function],
      default: () => {}
    },
    callBackCancel: {
      type: [Function],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    const { proxy } = getCurrentInstance()
    const fnCallBackCancel = event => {
      props.callBackCancel(proxy, event)
    }
    const fnCallBackDone = event => {
      props.callBackDone(proxy, event)
    }
    return { fnCallBackCancel, fnCallBackDone }
  }
}
</script>

<style scoped></style>
