import { reactive, toRefs, getCurrentInstance, computed } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    useDt: data.useDt || '',
    exNum: data.exNum || '',
    optionCountList: computed(() =>
      Array.from({ length: data.remainCount || 5 }, (v, i) => i)
    ),
    addCount: 1,
    goodsList: computed(() =>
      state.optionCountList
        .map(() => ({
          useDt: state.useDt,
          exNum: state.exNum
        }))
        .slice(0, state.addCount)
    ),
    isEdit: data.isEdit || false
  })

  const fnSave = async () => {
    if (!isValid()) return
    let goodsList
    if (state.isEdit) {
      const aGood = {
        ...data,
        useDt: state.useDt,
        exNum: state.exNum
      }
      delete aGood.isEdit
      goodsList = [aGood]
    } else {
      goodsList = [...state.goodsList]
    }

    proxy.$emit('close')
    callback(goodsList)
  }

  const maskingExNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    state.exNum = e.target.value
  }

  const isValid = () => {
    if (!proxy.$Util.isEmpty(state.useDt)) {
      if (proxy.$dayjs().diff(state.useDt, 'day') > 0) {
        alert('사용 기간은 과거일로 설정할 수 없습니다.')
        return false
      }
    }
    return true
  }

  return {
    ...toRefs(state),
    maskingExNumber,
    fnSave
  }
}
