<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div class="wzp_comment">
                <div class="rank">
                  <div class="con_ttl">전체 공지하기</div>
                  <div class="form_wrap">
                    <div class="form">
                      <ul class="form_ul">
                        <li class="form_li">
                          <div class="form_in">
                            <label>
                              <textarea
                                placeholder="전체 공지를 작성 해주세요."
                                v-model="message"
                                :maxlength="500"
                              ></textarea>
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnClose">취소</button>
              <button class="btn" @click="fnSend">전체 발송</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-created-party-notice-wizztalk-index',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
