import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { CommonFunction } from '@/common/CommonFunction'
import { niceForm } from '@/common/form/NiceForm'

export const componentState = ({ data }) => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { dispatch } = useStore()
  const state = reactive({
    item: { ...data },
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`
  })
  const { fnWrite, fnUpdateAuthType } = CommonFunction(state)
  const fnCreate = async () => {
    await fnWrite(async () => {
      await dispatch('party/updateHopeDetail', { hopeDetail: state.item })
    })
  }
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
      // await fnMyInfo()
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }
  return { ...toRefs(state), fnCreate, fnCallback }
}
