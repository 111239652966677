export const state = {
  isNotShowWizzTalkInfo: []
}
export const getters = {
  getIsNotShowWizzTalkInfo(state) {
    state.isNotShowWizzTalkInfo = localStorage.isNotShowWizzTalkInfo || '[]'
    return JSON.parse(state.isNotShowWizzTalkInfo)
  }
}
export const mutations = {
  setIsNotShowWizzTalkInfo(state, { isNotShowWizzTalkInfo = {} }) {
    state.isNotShowWizzTalkInfo = isNotShowWizzTalkInfo
    localStorage.isNotShowWizzTalkInfo = JSON.stringify(isNotShowWizzTalkInfo)
  }
}
export const actions = {
  updateIsNotShowWizzTalkInfo({ commit }, { isNotShowWizzTalkInfo = [] }) {
    commit('setIsNotShowWizzTalkInfo', { isNotShowWizzTalkInfo })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
