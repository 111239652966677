export const state = {
  hopeDetail: {},
  isNotShowInfo: []
}
export const getters = {
  getHopeDetail: state => {
    state.hopeDetail = localStorage.hopeDetail || '{}'
    return JSON.parse(state.hopeDetail)
  },
  getIsNotShowInfo(state) {
    state.isNotShowInfo = localStorage.isNotShowInfo || '[]'
    return JSON.parse(state.isNotShowInfo)
  }
}
export const mutations = {
  setHopeDetail(state, { hopeDetail }) {
    state.hopeDetail = hopeDetail
    localStorage.hopeDetail = JSON.stringify(hopeDetail)
  },
  setIsNotShowInfo(state, { isNotShowInfo = {} }) {
    state.isNotShowInfo = isNotShowInfo
    localStorage.isNotShowInfo = JSON.stringify(isNotShowInfo)
  }
}
export const actions = {
  updateHopeDetail({ commit }, { hopeDetail = {} }) {
    commit('setHopeDetail', { hopeDetail })
  },
  updateIsNotShowInfo({ commit }, { isNotShowInfo = [] }) {
    commit('setIsNotShowInfo', { isNotShowInfo })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
