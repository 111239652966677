import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'

class MarketSvc extends ServiceExec {
  /**
   * 마켓 리스트 (검색 포함)
   * @param params
   * @returns {*}
   */
  postMarketList(params = {}) {
    return this.post(`/api/market/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 디테일
   * @param params
   * @returns {*}
   */
  postMarketDetail(params = {}) {
    return this.post(`/api/market/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 결제페이지
   * @param params
   * @returns {*}
   */
  postMarketBuy(params = {}) {
    return this.post(`/api/market/buy`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 생성 페이지
   * @param params
   * @returns {*}
   */
  postMarketAddForm(params = {}) {
    return this.post(`/api/market/addForm`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 생성
   * @param params
   * @returns {*}
   */
  postMarketAdd(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      if (key === 'file' && typeof value === 'object') {
        // formData.append(key, value, value.name)
        for (let i = 0; i < value.length; i++) {
          formData.append('file', value[i], value[i].name)
        }
      } else {
        formData.append(key, value)
      }
    })
    return this.multiPost(`/api/market/add`, formData).then(response => {
      return response.data
    })
  }

  /**
   * [마켓] 위즈톡 UUID 가져오기
   * @param params
   * @returns {*}
   */
  getChatroomId(params = {}) {
    return this.post(`/api/chat/market/getChatroomId`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * [마켓] 위즈톡 대화 목록
   * @param params
   * @returns {*}
   */
  postChatList(params = {}) {
    return this.post(`/api/chat/market/chatList`, params).then(response => {
      return response.data
    })
  }

  /**
   * [마켓] 위즈톡 리스트(마이페이지)
   */
  postMyPageChatList(params = {}) {
    return this.post(`/api/myPage/chat/market/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * [마켓] 위즈톡 삭제 (마이페이지)
   * @param params
   * @returns {*}
   */
  postMyPageChatDelete(params = {}) {
    return this.post(`/api/myPage/chat/market/delete`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * [마켓] 위즈톡 안읽음 -> 읽음 변경
   * @param params
   * @returns {*}
   */
  postChangeChatRead(params = {}) {
    return this.post(`/api/chat/market/changeChatRead`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 판매한 마켓 리스트
   * @param params
   * @returns {*}
   */
  postMyPageSaleMarketList(params = {}) {
    return this.post(`/api/myPage/sale/market/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 상세페이지
   * @param params
   * @returns {*}
   */
  postMyPageSaleMarketDetail(params = {}) {
    return this.post(`/api/myPage/market/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 멤버 상세페이지
   * @param params
   * @returns {*}
   */
  postMyPageSaleMarketMemberDetail(params = {}) {
    return this.post(`/api/myPage/market/member/detail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 판매한 마켓 판매 중지
   * @param params
   * @returns {*}
   */
  postMyPageSaleMarketClose(params = {}) {
    return this.post(`/api/myPage/sale/market/close`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 간편 등록
   * @param params
   * @returns {*}
   */
  postMyPageMarketRegenerate(params = {}) {
    return this.post(`/api/myPage/market/regenerate`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 편집시 정보
   * @param params
   * @returns {*}
   */
  postMyPageMarketInfo(params = {}) {
    return this.post(`/api/myPage/market/info`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 편집
   * @param params
   * @returns {*}
   */
  postMyPageMarketInfoEdit(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      if (key === 'file' && typeof value === 'object') {
        // formData.append(key, value, value.name)
        for (let i = 0; i < value.length; i++) {
          formData.append('file', value[i], value[i].name)
        }
      } else {
        formData.append(key, value)
      }
    })
    return this.multiPost(`/api/myPage/market/update`, formData).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 등록된 이미지 편집 (마켓 편집시)
   * @param params
   * @returns {*}
   */
  postMyPageMarketImageDelete(params = {}) {
    return this.post(`/api/myPage/market/image/delete`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 마켓 삭제
   * @param params
   * @returns {*}
   */
  postMyPageMarketDelete(params = {}) {
    return this.post(`/api/myPage/market/delete`, params).then(response => {
      return response.data
    })
  }

  /**
   * 판매한 마켓 취소/환불 승인
   * @param params
   * @returns {*}
   */
  postMyPageCancelRefundAccept(params = {}) {
    return this.post(`/api/market/cancelRefundAccept`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 판매한 마켓 취소/환불 반려
   * @param params
   * @returns {*}
   */
  postMyPageCancelRefundReject(params = {}) {
    return this.post(`/api/market/cancelRefundReject`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 판매한 마켓 취소/환불 반려
   * @param params
   * @returns {*}
   */
  postMyPageCancelRefundModifyReply(params = {}) {
    return this.post(`/api/market/cancelRefundModifyReply`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 구매한 마켓 리스트(검색 포함)
   * @param params
   * @returns {*}
   */
  postMyPageBuyMarketList(params = {}) {
    return this.post(`/api/myPage/buy/market/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 구매한 마켓 상세 페이지
   * @param params
   * @returns {*}
   */
  postMyPageBuyMarketDetail(params = {}) {
    return this.post(`/api/myPage/buy/market/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 구매한 마켓 삭제
   * @param params
   * @returns {*}
   */
  postMyPageBuyMarketDelete(params = {}) {
    return this.post(`/api/myPage/buy/market/delete`, params).then(response => {
      return response.data
    })
  }

  /**
   * 정보입력(마켓장 정보 옵션 요청시)
   * @param params
   * @returns {*}
   */
  postMyPageBuyMarketOptions(params = {}) {
    return this.post(`/api/myPage/buy/market/options`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 정보수정(마켓장 정보 옵션 요청시)
   * @param params
   * @returns {*}
   */
  postMyPageBuyMarketOptionsUpdate(params = {}) {
    return this.post(`/api/myPage/buy/market/options/update`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 구매한 마켓 취소/환불 신청
   * @param params
   * @returns {*}
   */
  cancelRefundRequest(params = {}) {
    return this.post(`/api/market/cancelRefundRequest`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 구매한 마켓 취소/환불 신청 편집
   * @param params
   * @returns {*}
   */
  cancelRefundModify(params = {}) {
    return this.post(`/api/market/cancelRefundModify`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 구매한 마켓 취소/환불 신청 취소
   * @param params
   * @returns {*}
   */
  cancelRefundDelete(params = {}) {
    return this.post(`/api/market/cancelRefundDelete`, params).then(
      response => {
        return response.data
      }
    )
  }

  // api 규격서 2-3-7 구매가능한 pin 목록
  idelPinList(params = {}) {
    return this.post(`/api/market/idlePinList`, params).then(res => res.data)
  }

  // api 규격서 4-14-3 구매한 마켓 핀 상세페이지
  postMyPageBuyMarketPinDetail(params = {}) {
    return this.post(`/api/myPage/buy/market/pin/detail`, params).then(
      res => res.data
    )
  }

  // api 규격서 4-13-3 판매한 마켓 핀 번호 별 상세페이지
  postMyPageMarketPinDetail(params = {}) {
    return this.post(`/api/myPage/market/pin/detail`, params).then(
      res => res.data
    )
  }

  // api 규격서 4-14-9 구매한 마켓 PIN 번호 확인
  postMyPageBuyMarketConfirmPin(params = {}) {
    return this.post(`/api/myPage/buy/market/confirm/pin`, params).then(
      res => res.data
    )
  }

  // api 규격서 4-13-12 판매한 마켓 구매확정 요청
  postMyPageMarketRequestApproval(params = {}) {
    return this.post(`/api/myPage/market/requestApproval`, params).then(
      res => res.data
    )
  }

  // api 규격서 4-14-10 구매한 마켓 구매자 정보요청 수정
  postMyPageBuyMarketUpdateResOption(params = {}) {
    return this.post(`/api/myPage/buy/market/updateResOption`, params).then(
      res => res.data
    )
  }

  // api 규격서 4-14-11 구매한 마켓 사용 완료 처리
  postMyPageBuyMarketUseComplete(params = {}) {
    return this.post(`/api/myPage/buy/market/useComplete`, params).then(
      res => res.data
    )
  }
}

export default new MarketSvc()
