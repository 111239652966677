import { reactive, toRefs, computed, getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    tabIndex: computed(() => getters['cs/getTabIndex']),
    tabs: proxy.$ConstCode.CS_TABS
  })
  const fnTabIndex = async tabIndex => {
    await router.replace({
      path: proxy.$ConstCode.CS_TABS.find(tab => tab.index === tabIndex).path
    })
  }
  /** watch **/
  watch(
    () => route.path,
    async val => {
      let tab = state.tabs[0]
      state.tabs.some(item => {
        if (val.startsWith(item.path)) {
          tab = item
          return true
        }
        return false
      })
      if (!proxy.$Util.isEmpty(tab)) {
        await dispatch('cs/updateTabIndex', { tabIndex: tab.index })
      }
    },
    { immediate: true }
  )
  const init = () => {
    if (proxy.$Util.isEmpty(state.userData)) {
      state.tabs = state.tabs.filter(item => {
        return !item.isUserOnly
      })
    }
  }
  init()
  return { ...toRefs(state), fnTabIndex }
}
