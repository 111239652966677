import { reactive, toRefs, getCurrentInstance, computed } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = ({ data }) => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    party: { ...data },
    diffDay: computed(() => {
      if (proxy.$Util.isEmpty(state.party)) return 0
      const startDate = proxy.$dayjs(state.party.regDt).format('YYYY-MM-DD')
      const doneDate = proxy.$dayjs(state.party.useDt).format('YYYY-MM-DD')
      return proxy.$dayjs(doneDate).diff(proxy.$dayjs(startDate), 'day')
    }),
    totalPrice: computed(() => {
      if (proxy.$Util.isEmpty(state.party)) return 0
      if (state.party.acntTransferYn === 'N') {
        return state.diffDay * Number(state.party.dailyPrice)
      } else {
        return Number(state.party.dailyPrice)
      }
    })
  })
  const fnRegenerate = () => {
    if (!confirm('재등록 하시겠습니까?')) return
    const query = {
      partyNo: state.party.partyNo
    }
    return router.push({
      name: 'my-page-created-party-edit-index',
      params: { regenerate: 'true' },
      query
    })
  }
  return { ...toRefs(state), fnRegenerate }
}
