import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const state = reactive({
    form: {
      seq: '',
      partyNo: route.query.partyNo,
      option: '',
      msg: '',
      isEdit: proxy.$ConstCode.BOOLEAN_VALUE.FALSE
    }
  })
  const fnSave = async () => {
    if (!isValid()) return
    const params = {}
    params.seq = state.form.seq
    params.partyNo = state.form.partyNo
    params.option = state.form.option
    params.msg = state.form.msg
    let res = ''
    if (state.form.isEdit === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
      res = await proxy.$PartySvc.postPartyCancelRefundModify(params)
    } else {
      res = await proxy.$PartySvc.postPartyCancelRefundRequest(params)
    }

    if (res.resultCode === '0000') {
      alert(
        '취소/ 환불 신청이 접수되었습니다. 접수시점을 기준으로 분쟁 해결까지는 잔여일 소진이 일시 중지됩니다.  분쟁내용의 처리는 약 3일정도 소요됩니다.'
      )
      callback()
      proxy.$emit('close')
    } else {
      alert(res.resultMsg)
    }
  }
  const init = () => {
    if (!proxy.$Util.isEmpty(data)) {
      proxy.$_.merge(state.form, data)
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.form.option)) {
      alert('취소 환불 사유를 선택해주세요.')
      return false
    }
    if (proxy.$Util.isEmpty(state.form.msg)) {
      alert('취소 환불 사유를 입력해주세요.')
      return false
    }
    return true
  }
  init()
  return { ...toRefs(state), fnSave }
}
