<template>
  <div class="inner">
    <div class="depth01">
      <div class="left">
        <div class="btn_wrap">
          <button
            :class="{
              on:
                !$Util.isEmpty(onDepth1Item) &&
                onDepth1Item.categoryID === item.categoryID &&
                !isShowMyPage &&
                !isShowCS
            }"
            v-for="item in categoryList"
            :key="item.categoryID"
            @click="() => fnPage(item)"
          >
            {{ item.categoryName }}
          </button>
          <button
            :class="{
              on: isShowMyPage
            }"
            v-if="!$Util.isEmpty(userData)"
            @click="fnMyPage"
          >
            마이페이지<span style="color: #ee0000;" v-if="myPageBadge > 0"
              >N</span
            >
          </button>
        </div>
      </div>
      <div class="right">
        <button class="btn_search" @click="fnToggle"></button>
      </div>
    </div>
    <div id="search_area" class="on" v-if="isShow">
      <label class="input srh_type01">
        <input
          type="search"
          placeholder="검색어를 입력해주세요."
          v-model="text"
          :maxlength="100"
          @keyup.enter="fnUnion"
        />
      </label>
    </div>
    <category-community v-if="isShowCommunity" />
  </div>
</template>

<script>
import { componentState } from './index.js'
import CategoryCommunity from '@/components/common/layouts/nav-bar/category/community/index.vue'

export default {
  name: 'nav-bar-category-index',
  components: { CategoryCommunity },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
