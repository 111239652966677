import { useStore } from 'vuex'
import { computed, reactive, toRefs, watch } from 'vue'

export const componentState = () => {
  const { getters } = useStore()
  const state = reactive({
    isLoading: computed(() => getters['layout/getIsLoading']),
    isShow: false,
    timer: ''
  })
  /** watch **/
  watch(
    () => state.isLoading,
    val => {
      if (state.timer) {
        clearTimeout(state.timer)
        state.timer = ''
      }
      if (val) {
        state.isShow = true
      } else {
        state.timer = setTimeout(() => {
          state.isShow = false
        }, 300)
      }
    }
  )
  return { ...toRefs(state) }
}
