<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="party_list_li" style="height: auto;">
                  <div class="party_tag center big">
                    <!--'중앙: center','큰사이즈: big'-->
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        :class="{ sl: party.acntTransferYn === 'N' }"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            party.categoryId
                          ).color
                        }"
                      >
                        {{ party.categoryNm }}
                      </div>
                    </div>
                    <div class="right">
                      <!--<div class="pt_tag new"></div>-->
                    </div>
                  </div>
                  <div class="party_ttl">{{ party.title }}</div>
                  <div class="party_con" style="position: unset;">
                    <div class="left">
                      <div class="party_info">
                        <dl>
                          <dt>등록일 :</dt>
                          <dd>
                            {{ $dayjs(party.regDt).format('YYYY.MM.DD') }}
                          </dd>
                        </dl>
                        <dl>
                          <dt>종료일 :</dt>
                          <dd v-if="party.acntTransferYn === 'N'">
                            {{
                              `${$dayjs()
                                .add(diffDay, 'day')
                                .format('YYYY.MM.DD')} (일 평균 ${
                                party.dailyPrice
                              }원)`
                            }}
                          </dd>
                          <dd v-else-if="party.unlimitedCheckYn === 'N'">
                            {{
                              `${$dayjs()
                                .add(diffDay, 'day')
                                .format('YYYY.MM.DD')}`
                            }}
                          </dd>
                          <dd v-else>표시 없음</dd>
                        </dl>
                        <!--                        <dl>
                          <dt>파티신청 :</dt>
                          <dd>
                            {{
                              $Util.formatNumber2(party.recruitNum, {
                                unit: '명'
                              })
                            }}
                          </dd>
                        </dl>-->
                        <dl>
                          <dt>현재 판매가 :</dt>
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(totalPrice, { unit: '' })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnRegenerate">파티 생성</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-regenerate',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
