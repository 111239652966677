<template>
  <div class="pt_modal" :style="{ '--party-guide-bg': color }">
    <!--이미지 배경색-->
    <div class="pt_modal_con">
      <div class="img_area">
        <img
          src="@/assets/static/images/img_pt_guide_03.png"
          alt="파티 생성 안내"
        />
        <!--        <img
          src="@/assets/static/images/img_pt_guide_02.png"
          alt="파티 생성하기 가이드"
          v-else-if="tabIndex === 1"
        />-->
      </div>
      <button class="btn_skip" @click="$emit('close')">skip</button>
    </div>
    <!--    <div class="pt_modal_btn" v-if="tabIndex === 0">
      <div class="btn_wrap">
        <button class="btn" @click="() => (tabIndex = 1)">다음</button>
      </div>
    </div>-->
    <div class="pt_modal_btn">
      <label class="checkbox">
        <input
          type="checkbox"
          :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
          :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
          :checked="isNotShowInfo === $ConstCode.BOOLEAN_VALUE.TRUE"
          v-model="isNotShowInfo"
          @change="fnIsNotShowInfo"
        />
        <span class="label"><span>더 이상 보지 않기</span></span>
      </label>
      <div class="btn_wrap">
        <button class="btn" @click="$emit('close')">파티 생성하기</button>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-party-info',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
