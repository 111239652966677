<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="market_thumb">
                  <div class="img_area">
                    <img src="@/assets/static/images/img_event_olympic.png" alt="" />
                  </div>
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_ttl" style="color: #999; font-weight: 500;">
                          답변 입력
                        </div>
                        <div class="form_in">
                          <label class="input">
                            <input
                              type="text"
                              :placeholder="data.inputPlaceholder"
                              @input="maskingEventInputText"
                              :value="eventInputText"
                              :maxlength="3"
                            />
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">{{ data.submitText || '제출' }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-custom-event-input',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
