import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    item: {
      ...data
    },
    reply: data.reply || ''
  })
  const fnSave = async () => {
    if (proxy.$Util.isEmpty(state.reply)) {
      alert('내용을 입력해주세요.')
      return false
    }
    callback(state.reply)
    proxy.$emit('close')
  }

  return { ...toRefs(state), fnSave }
}
