<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div style="position: static">
        <div class="modal_inner center">
          <div
            style="display: flex; flex-direction: column; width: 100%; height: 100%;"
          >
            <iframe
              id="settlePop"
              @load="iframeOnload"
              style="position: absolute; width: 100%; height: 100%;"
              :name="data.target"
              frameborder="0"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-settle-bank-popup',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
