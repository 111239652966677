import ServiceExec from '@/common/utils/ServiceExec'
/**
 * User API Service
 * @class SignSvc
 */
class FcmSvc extends ServiceExec {
  /**
   * FCM 토큰 등록
   * @param params
   * @returns {*}
   */
  postRegister(params = {}) {
    return this.post(`/api/fcm/register`, params).then(response => {
      return response.data
    })
  }

  /**
   * 등록된 토큰 삭제
   * @param params
   * @returns {*}
   */
  postUnregister(params = {}) {
    return this.post(`/api/fcm/unregister`, params).then(response => {
      return response.data
    })
  }
}
export default new FcmSvc()
