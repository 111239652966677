import { getCurrentInstance, reactive, toRefs } from 'vue'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    pin: '',
    confirm: data.confirm,
    exNum: '-',
    type: data.type || '0',
    thumbnail: ''
  })
  const fnConfirm = async () => {
    const params = { ...data.params }
    const res = await proxy.$MarketSvc.postMyPageBuyMarketConfirmPin(params)
    if (res.resultCode == '0000') {
      state.pin = res.pin
      state.exNum = res.exNum ? res.exNum : '-'
      state.thumbnail = res.thumbnail ? res.thumbnail[0] : ''
      state.confirm = '1'
      callback()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnModalPinZoom = async () => {
    const params = {
      component: proxy.$modalAlertNames.PIN_ZOOM,
      data: {
        pin: state.pin
      },
      callback: () => {}
    }

    await fnModalAlert(params)
  }

  const getStatusStyle = status =>
    status == 2 || status == 4
      ? { done: true }
      : status == 1
      ? { waiting: true }
      : status == 0
      ? {}
      : { conflict: true }

  const init = async () => {
    if (data.confirm == '1') await fnConfirm()
  }

  init()

  return { ...toRefs(state), fnConfirm, getStatusStyle, fnModalPinZoom }
}
