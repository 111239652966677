import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import img1 from '@/assets/static/images/img_sv_guide_01.png'
import img2 from '@/assets/static/images/img_sv_guide_02.png'
import img3 from '@/assets/static/images/img_sv_guide_03.png'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    items: [img1, img2, img3],
    activeIndex: 0
  })
  const onSlideChange = event => {
    state.activeIndex = event.activeIndex
  }
  const fnPage = async () => {
    if (proxy.$Util.isEmpty(state.userData)) {
      await router.push({ path: '/login' })
    } else {
      proxy.$emit('close')
    }
  }
  return { ...toRefs(state), onSlideChange, fnPage }
}
