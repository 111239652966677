<template>
  <div class="push on">
    <!--'on'-->
    <div
      class="inner"
      v-for="(item, index) in pushAlarms"
      :key="index"
      @click="item.callback(index)"
    >
      <div class="left">
        <div class="icon"></div>
      </div>
      <div class="right">
        <div class="top">
          <div class="from">WIZZNEY</div>
          <div class="time">{{ $dayjs(item.regDt).format('YYYY.MM.DD') }}</div>
        </div>
        <div class="con">
          <div class="txt">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'push-alarm-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
