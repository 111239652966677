<template>
  <components-index />
</template>

<script>
import ComponentsIndex from '@/components/index.vue'

export default {
  name: 'App',
  components: { ComponentsIndex }
}
</script>

<style scoped></style>
