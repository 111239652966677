import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class ChatSvc extends ServiceExec {
  /**
   * (폴링) 마이페이지 메인 – 위즈톡
   * @param params
   * @returns {*}
   */
  postCheckChatReadYn(params = {}) {
    return this.post(`/api/chat/checkChatReadYn`, params).then(response => {
      return response.data
    })
  }

  /**
   * 위즈톡 UUID 가져오기
   * @param params
   * @returns {*}
   */
  postGetChatroomId(params = {}) {
    return this.post(`/api/chat/getChatroomId`, params).then(response => {
      return response.data
    })
  }

  /**
   * (마켓) 위즈톡 UUID 가져오기
   * @param params
   * @returns {*}
   */
  postGetMarketChatroomId(params = {}) {
    return this.post(`/api/chat/market/getChatroomId`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 위즈톡 대화 목록
   * @param params
   * @returns {*}
   */
  postChatList(params = {}) {
    return this.post(`/api/chat/chatList`, params).then(response => {
      return response.data
    })
  }

  /**
   * 위즈톡 안읽음 -> 읽음 변경
   * @param params
   * @returns {*}
   */
  postChangeChatRead(params = {}) {
    return this.post(`/api/chat/changeChatRead`, params).then(response => {
      return response.data
    })
  }
}
export default new ChatSvc()
