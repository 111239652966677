import { getCurrentInstance, reactive, toRefs, onMounted } from 'vue'
import fitvids from 'fitvids'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  // const route = useRoute()
  const state = reactive({})
  const fnClose = async () => {
    proxy.$emit('close')
  }

  onMounted(() => {
    fitvids({
      players: `iframe[src*="https://www.youtube.com/embed/${props.data.code}?playerapiid=ytplayer"`
    })
  })

  return { ...toRefs(state), fnClose }
}
