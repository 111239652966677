<template>
  <header class="header">
    <div class="inner" v-show="routeLayout.isHeader">
      <div class="left">
        <button
          class="h_btn back"
          v-show="routeLayout.isBack"
          @click="fnBack"
        ></button>
      </div>
      <h1 class="logo">
        <a href="javascript:return" @click="fnMain"
          ><img src="@/assets/static/images/logo_header.svg" alt="위즈니"
        /></a>
      </h1>
      <div class="right">
        <button
          class="h_btn log"
          @click="fnLoginPage"
          v-if="routeLayout.isLogin && $Util.isEmpty(userData)"
        >
          로그인
        </button>
        <!-- {
              nonebg:
                !$Util.isEmpty(userData.profile) &&
                userData.profile.indexOf('/null') < 0
            }, -->
        <div
          class="prof nonebg"
          :class="[
            {
              off:
                !$Util.isEmpty(userData.userLvId) &&
                Number(userData.userLvId) === $ConstCode.LEVEL_TYPE['0'].value
            }
          ]"
          @click="fnShowMyMenu"
          v-else-if="routeLayout.isLogin && !$Util.isEmpty(userData)"
        >
          <!--'미인증: off 추가', '우수회원: vip 추가'-->
          <!-- <div class="img_area">
            <img
              :src="userData.profile"
              alt=""
              v-if="
                !$Util.isEmpty(userData.profile) &&
                  userData.profile.indexOf('/null') < 0
              "
            />
          </div> -->
          <div class="newsvg" v-if="Number(myPageBadge) === 1">
            <img src="@/assets/static/images/i_new_in_badge.svg" />
          </div>
          <div>
            <img src="@/assets/static/images/i_menu.svg" />
          </div>
        </div>
      </div>
      <div
        id="my_menu"
        :class="{ on: isShowMyMenu && !$Util.isEmpty(userData) }"
        @click="fnShowMyMenu"
      >
        <div class="top">
          <div
            class="prof"
            :class="[
              {
                nonebg:
                  !$Util.isEmpty(userData.profile) &&
                  userData.profile.indexOf('/null') < 0
              },
              {
                off:
                  !$Util.isEmpty(userData.userLvId) &&
                  Number(userData.userLvId) === $ConstCode.LEVEL_TYPE['0'].value
              },
              {
                vip:
                  !$Util.isEmpty(userData.sellerLv) &&
                  Number(userData.sellerLv) >= 3
              }
            ]"
          >
            <!--'미인증: off 추가', '우수회원: vip 추가'-->
            <div class="img_area">
              <img
                :src="userData.profile"
                alt=""
                v-if="
                  !$Util.isEmpty(userData.profile) &&
                    userData.profile.indexOf('/null') < 0
                "
              />
            </div>
          </div>
          <div class="nickname">
            {{
              userData.nick ||
                userData.name ||
                userData.userId ||
                `${platform}_${userData.snsId}`
            }}
          </div>
          <div class="id">
            <span>{{
              userData.userId || `${platform}_${userData.snsId}`
            }}</span>
            <div
              class="sv_tag"
              :class="[
                {
                  kakao:
                    Number(userData.loginType) ===
                    $ConstCode.SNS_LOGIN_TYPE.KAKAO.value
                },
                {
                  naver:
                    Number(userData.loginType) ===
                    $ConstCode.SNS_LOGIN_TYPE.NAVER.value
                },
                {
                  google:
                    Number(userData.loginType) ===
                    $ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
                },
                {
                  apple:
                    Number(userData.loginType) ===
                    $ConstCode.SNS_LOGIN_TYPE.APPLE.value
                }
              ]"
              v-if="!$Util.isEmpty(userData.loginType)"
            >
              {{
                Number(userData.loginType) === 0
                  ? '카카오'
                  : Number(userData.loginType) === 1
                  ? '네이버'
                  : Number(userData.loginType) === 2
                  ? '구글'
                  : '애플'
              }}
            </div>
          </div>
        </div>
        <div class="btm">
          <ul>
            <li>
              <button
                :class="{ new: Number(myPageBadge) === 1 }"
                @click="fnMyPage"
              >
                마이페이지
              </button>
            </li>
            <li><button @click="fnMyInfo">개인정보 수정</button></li>
            <li><button @click="fnCenter">고객센터</button></li>
          </ul>
        </div>
        <button class="btn_logout" @click="fnLogout">로그아웃</button>
      </div>
    </div>
    <nice
      :id="'formNice2'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallback"
    />
  </header>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'header-bar-index',
  setup() {
    return {
      ...componentState()
    }
  }
}
</script>

<style scoped></style>
