<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>추가 정보 등록</span>
                </div>
                <div class="hint">{{ data.reqOptionMsg }}</div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_in">
                          <label>
                            <textarea
                              placeholder="50자 이내로 작성"
                              v-model="reply"
                            ></textarea>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-add-info',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
