<template>
  <div class="pt_modal" style="--party-guide-bg: #00132A;">
    <!--이미지 배경색-->
    <div class="pt_modal_con">
      <div class="img_area">
        <img
          src="@/assets/static/images/img_tk_guide_01.png"
          alt="위즈톡 이용 가이드"
        />
      </div>
    </div>
    <div class="pt_modal_btn">
      <label class="checkbox">
        <input
          type="checkbox"
          :true-value="$ConstCode.BOOLEAN_VALUE.TRUE"
          :false-value="$ConstCode.BOOLEAN_VALUE.FALSE"
          :checked="isNotShowWizzTalkInfo === $ConstCode.BOOLEAN_VALUE.TRUE"
          v-model="isNotShowWizzTalkInfo"
          @change="fnIsNotShowWizzTalkInfo"
        />
        <span class="label"><span>더 이상 보지 않기</span></span>
      </label>
      <div class="btn_wrap">
        <button class="btn" @click="fnClose">확인</button>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-wizz-talk-info',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
