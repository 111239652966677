import { computed, reactive, toRefs, watch, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const state = reactive({
    pushAlarms: computed(() => getters['pushAlarm/getPushAlarms'])
  })
  watch(
    () => state.pushAlarms,
    val => {
      if (!proxy.$Util.isEmpty(val)) {
        setTimeout(async () => {
          await dispatch('pushAlarm/popPushAlarm', {})
        }, 5000)
      }
    },
    { immediate: true, deep: true }
  )
  return { ...toRefs(state) }
}
