import { reactive, toRefs, getCurrentInstance } from 'vue'
import {
  justSendMessage,
  connectPromise,
  disconnectPromise
} from '@/assets/static/js/wizzneyWebSocket.js'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    message: '',
    sellerChatroomId: ''
  })

  const fnSend = async () => {
    if (!isValid()) return
    await fnAllWsSendMessage()
    await disconnectPromise()
    proxy.$emit('close')
    callback()
  }

  const fnChatroomId = async () => {
    const res = await proxy.$ChatSvc.postGetChatroomId({
      partyNo: data.partyNo
    })
    if (res.resultCode === '0000') {
      state.sellerChatroomId = res.chatroomId
    }
  }

  const fnAllWsSendMessage = async () => {
    if (proxy.$Util.isEmpty(state.message)) return
    const body = {
      message: state.message,
      partyNo: data.partyNo,
      sellerChatroomId: state.sellerChatroomId,
      fullNotice: 1
    }

    await connectPromise()

    const sendPromises = []

    for (let buyerChatroomId of data.membersChatroomId) {
      sendPromises.push(
        justSendMessage('/app/chat.message/toBuyer', {
          ...body,
          buyerChatroomId
        })
      )
    }

    await Promise.all(sendPromises)

    return
  }

  const isValid = () => {
    if (proxy.$Util.isEmpty(state.message)) {
      alert('전체 공지사항을 입력해주세요.')
      return false
    }
    return true
  }

  const fnClose = () => {
    proxy.$emit('close')
  }

  const init = async () => {
    await fnChatroomId()
  }

  init()

  return { ...toRefs(state), fnSend, fnClose }
}
