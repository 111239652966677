import { computed, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export const componentState = () => {
  const { getters } = useStore()
  const route = useRoute()
  const state = reactive({
    routeLayout: computed(() => getters['layout/getRouteLayout']),
    navIndex: computed(() => {
      if (
        route.path.startsWith('/main') ||
        route.path.startsWith('/party') ||
        route.path.startsWith('/community')
      ) {
        return 0
      } else if (route.path.startsWith('/cs')) {
        return 1
      } else if (route.path.startsWith('/my-page')) {
        return 2
      }
      return 0
    })
  })
  return { ...toRefs(state) }
}
