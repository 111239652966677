<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>구매 옵션</span>
                </div>
                <div class="table_type03" style="margin-top: 1rem;">
                  <dl>
                    <dt>상품명</dt>
                    <dd>{{ data.title }}</dd>
                  </dl>
                </div>
                <div class="form" style="margin-top: 1rem;">
                  <ul class="form_ul">
                    <li
                      class="form_li"
                      v-for="(item, index) in computedPinList"
                      :key="index"
                    >
                      <div class="inline">
                        <label class="checkbox" style="flex: 0;">
                          <input
                            v-model="checkList"
                            type="checkbox"
                            :value="item"
                            @change="onchange"
                          />
                          <span class="label"></span>
                        </label>
                        <label class="input">
                          <input :value="item.text" disabled />
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="cal">
                  <div class="call">
                    수량 : <span>{{ count }}</span
                    >개
                  </div>
                  <div>
                    <span class="calll">{{
                      $Util.formatNumber2(computedTotalPrice, { unit: '' })
                    }}</span>
                    원
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">선택 완료</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-choose-pin-number',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
