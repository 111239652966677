<template>
  <form
    action="https://nice.checkplus.co.kr/CheckPlusSafeModel/checkplus.cb"
    method="post"
    :id="id"
    :target="target"
  >
    <input type="hidden" name="m" :value="m" />
    <input type="hidden" name="EncodeData" :value="encodeData" />
  </form>
</template>

<script>
import { watch } from 'vue'
import { openNiceAuthPop } from '@/common/GlobalFunction'

export default {
  name: 'component-nice-index',
  props: {
    id: {
      type: [String],
      default: 'formNice'
    },
    target: {
      type: [String],
      default: 'nice-auth-popup'
    },
    m: {
      type: [String],
      default: 'checkplusSerivce'
    },
    encodeData: {
      type: [String],
      default: 'encodeDatavsl'
    },
    callType: {
      type: [Number, String],
      default: ''
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    watch(
      () => props.encodeData,
      async () => {
        const newWindow = window.open('about:blank', props.target)
        setTimeout(() => {
          document.getElementById(props.id).submit()
          openNiceAuthPop(newWindow, props.callback, {
            m: props.m,
            encodeData: props.encodeData,
            callType: props.callType
          })
        }, 100)
      }
    )
    return { ...props }
  }
}
</script>

<style scoped></style>
