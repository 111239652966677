export const registerForm = {
  userId: '', // 아이디 (일반 가입시, 필수) *
  pw: '', // 비밀번호 *
  niceId: '', // 휴대폰 인증시 나오는 값 (필수) *
  niceSeq: '', // 휴대폰 인증시 나오는 값 (필수) *
  nick: '', // 닉네임 *
  accountNum: '', // 계좌번호 *
  bankCode: '', // 은행코드 *
  useAgree: 1, // 이용약관 동의 (0:동의/1:비동의) (필수) *
  priAgree: 1, // 개인정보 수집 및 이용 동의 (0:동의/1:비동의) (필수) *
  loginType: '', // 0 카카오 1 네이버 2 구글 (SNS 일때, 필수)
  id: '', // Sns 아이디 (필수)
  accountName: '', // 예금주명,
  isIdDupl: 1, // 아이디 사용가능여부(0: 인증, 1: 미인증)
  isPhoneAuth: 1, // 휴대폰 인증여부(0: 인증, 1: 미인증)
  isNickDupl: 1, // 닉네임 중복확인여부 (0: 중복체크완료, 1: 중복체크미완료)
  name: '', // 본인인증후 이름
  phone: '', // 본인인증후 휴대폰번호
  birth: '', // 본인인증후 생년월일
  smsAgree: 1,
  emailAgree: 1
}
