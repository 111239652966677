import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    stars: ['', '', '', '', ''],
    form: {
      id: data.data.id,
      category: data.data.category,
      seq: !proxy.$Util.isEmpty(data.form) ? data.form.seq : '',
      starRating: !proxy.$Util.isEmpty(data.form) ? data.form.starRating : 0,
      comment: !proxy.$Util.isEmpty(data.form) ? data.form.comment : '',
      tag: !proxy.$Util.isEmpty(data.form) ? data.form.tag : ''
    }
  })
  const fnScore = index => {
    fnStar(index)
    state.form.starRating = index + 1
  }
  const fnSave = async () => {
    if (!isValid()) return
    let res = {}
    if (!data.isReComment) {
      if (!data.data.isModify) {
        res = await proxy.$WizzpediaSvc.postWizzpediaWriteComment(state.form)
      } else {
        res = await proxy.$WizzpediaSvc.postWizzpediaModifyComment(state.form)
      }
    } else {
      // 댓글인 경우
      if (!state.form.comment.includes(data.form.tag)) {
        data.form.tag = ''
      }
      data.form.comment = state.form.comment.replace(`${data.form.tag} `, '')
      if (!data.data.isModify) {
        res = await proxy.$WizzpediaSvc.postWizzpediaWriteNestedComment(
          data.form
        )
      } else {
        res = await proxy.$WizzpediaSvc.postWizzpediaModifyNestedComment(
          data.form
        )
        // res = await proxy.$WizzpediaSvc.postWizzpediaModifyNestedComment(data.form)
      }
    }
    if (res.resultCode === '0000') {
      callback()
      proxy.$emit('close')
    }
    alert(res.resultMsg)
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.form.comment)) {
      alert('댓글 내용을 입력해주세요.')
      return false
    }
    if (state.form.comment.length < 10) {
      alert('댓글입력은 최소 10자이상 입력해주세요.')
      return false
    }
    return true
  }
  const fnStar = index => {
    state.stars = state.stars.map((sItem, sIndex) => {
      if (sIndex < index) {
        return { on: true, half: false }
      } else if (sIndex === index) {
        return { on: true, half: false }
      }
      return { on: false, half: false }
    })
  }
  const init = () => {
    if (state.form.starRating > 0) {
      const index = Number(state.form.starRating.toFixed(0))
      fnStar(index - 1, 0)
    }
    // if (data.isReComment) {
    //   state.form.comment = `${data.form.tag} ${data.form.comment}`
    // }
  }
  init()
  return { ...toRefs(state), fnScore, fnSave }
}
