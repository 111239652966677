<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>취소/환불 반려 사유</span>
                </div>
                <div class="hint">
                  * 파티원이 잘 확인할 수 있도록 작성해주세요.
                </div>
                <div class="hint">
                  * 급한 내용은 위즈톡을 이용해서 채팅해주세요.
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_in">
                          <label>
                            <textarea
                              v-model="form.reply"
                              :maxlength="500"
                              placeholder="500자 이내로 작성"
                            ></textarea>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-market-refund-reject',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
