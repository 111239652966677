<template>
  <nav class="nav" v-show="routeLayout.isNav">
    <nav-bar-category v-if="routeLayout.isNav" />
    <nav-bar-cs v-if="routeLayout.isNav && navIndex === 1" />
    <nav-bar-my-page v-if="routeLayout.isNav && navIndex === 2" />
  </nav>
</template>

<script>
import { componentState } from './index.js'
import NavBarCategory from '@/components/common/layouts/nav-bar/category/index.vue'
import NavBarCs from '@/components/common/layouts/nav-bar/cs/index.vue'
import NavBarMyPage from '@/components/common/layouts/nav-bar/my-page/index.vue'

export default {
  name: 'nav-bar-index',
  components: { NavBarCategory, NavBarCs, NavBarMyPage },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
