import firebase from 'firebase/app'
import 'firebase/messaging'
import ConstCode from '@/common/constants/ConstCode'
import Util from '@/common/utils/Util'
import router from '@/router'
import store from '@/store'
import dayjs from 'dayjs'

let _newWindow = {}
let _callbackFunction = () => {}
let _niceForm = {}
export const openNiceAuthPop = (newWindow, callbackFunction, niceForm) => {
  _newWindow = newWindow
  _callbackFunction = callbackFunction
  _niceForm = niceForm
}

let _newWindow2 = {}
let _callbackFunction2 = () => {}
export const openSnsPop = (newWindow2, callbackFunction2) => {
  _newWindow2 = newWindow2
  _callbackFunction2 = callbackFunction2
}

let _callbackFunction3 = () => {}
export const openSettlePop = callbackFunction3 => {
  _callbackFunction3 = callbackFunction3
}

window.addEventListener('message', function({ data }) {
  if (!Util.isEmpty(data.source) && data.source === 'niceAuthPopSuccess') {
    // 본인인증 콜백 message 처리
    if (
      !Util.isEmpty(_niceForm.callType) &&
      _niceForm.callType === ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
    ) {
      data.params.callType = _niceForm.callType
    }
    _callbackFunction(data.params)
    _newWindow.postMessage(
      {
        source: 'niceAuthPopClose'
      },
      '*'
    )
  } else if (
    !Util.isEmpty(data.source) &&
    data.source === 'niceAuthPopSuccessAndAlready'
  ) {
    // 본인인증 콜백 message 처리
    _callbackFunction(false)
    _newWindow.postMessage(
      {
        source: 'niceAuthPopClose'
      },
      '*'
    )
  } else if (!Util.isEmpty(data.source) && data.source === 'snsPopResult') {
    _callbackFunction2(data.params)
    _newWindow2.postMessage(
      {
        source: 'snsPopClose'
      },
      '*'
    )
  } else if (!Util.isEmpty(data.source) && data.source === 'settlePopResult') {
    window.onpopstate = () => {}
    _callbackFunction3(data.params)
  }
})

// 브라우저 지원 가능할경우
try {
  const config = { ...ConstCode.CODE_ENV_LIST.FCM_CONFIG }

  firebase.initializeApp(config)

  const messaging = firebase.messaging()
  messaging.usePublicVapidKey(ConstCode.CODE_ENV_LIST.FCM_PUBLIC_VAPID_KEY)

  messaging.onMessage(async payload => {
    console.log('[foreground]', payload)
    const title = payload.notification.body
    const notification = { ...payload.notification }
    const data = { ...payload.data }
    const pushAlarm = {
      title: title,
      notification: notification,
      data: data,
      regDt: dayjs(),
      callback: async index => {
        if (!Util.isEmpty(data)) {
          const query = {}
          let path = ''
          if (Number(data.kind) === 0) {
            // 위즈톡 채팅
            query.partyNo = data.value
            query.chatroomId = data.value2
            query.type = data.value3
            path = '/wizz-talk'
          } else if (Number(data.kind) === 1) {
            // 참여한 파티 상세
            if (Util.isEmpty(data.value)) {
              path = '/my-page/joined-party'
            } else {
              // 참여한 파티 상세
              query.partyNo = data.value
              path = '/my-page/joined-party/view'
            }
          } else if (Number(data.kind) === 2) {
            if (Util.isEmpty(data.value)) {
              path = '/my-page/created-party'
            } else {
              // 생성한 파티 상세
              query.partyNo = data.value
              path = '/my-page/created-party/view'
            }
          } else if (Number(data.kind) === 3) {
            // 공지사항/이벤트
            path = '/cs/notice'
          } else if (Number(data.kind) === 4) {
            // 마이페이지 > 페널티게시판
            path = '/my-page/penalty'
          } else if (Number(data.kind) === 5) {
            // 메인페이지
            query.isRoot = 1
            path = '/main'
          } else if (Number(data.kind) === 6) {
            // 마이페이지 > 파티알림
            path = '/my-page/party-noti'
          } else if (Number(data.kind) === 7) {
            // 마이페이지
            path = '/my-page/home'
          } else if (Number(data.kind) === 8) {
            if (Util.isEmpty(data.value)) {
              path = '/my-page/market'
              query.index = 0
            } else {
              // 구매한 마켓 상세
              query.marketNo = data.value
              query.memberSeq = data.value2
              path = '/my-page/market/buyer-view'
            }
          } else if (Number(data.kind) === 9) {
            if (Util.isEmpty(data.value)) {
              path = '/my-page/market'
              query.index = 1
            } else {
              // 판매한 마켓 상세
              query.marketNo = data.value
              path = '/my-page/market/view'
            }
          } else if (Number(data.kind) === 10) {
            // 위즈톡 채팅
            query.marketNo = data.value
            query.chatroomId = data.value2
            query.memberSeq = data.value3
            query.type = data.value4
            path = '/market/wizz-talk'
          }
          if (!Util.isEmpty(path)) {
            const signKinds = [0, 1, 2, 4, 6, 7, 8, 9, 10]
            const userData = store.getters['user/getData']
            if (
              signKinds.indexOf(Number(data.kind)) > -1 &&
              Util.isEmpty(userData)
            ) {
              alert('로그인이 필요합니다.')
              await router.push({ path: '/login' })
            } else {
              await router.push({ path: path, query: query })
            }
          }
        }
        await store.dispatch(
          'pushAlarm/popPushAlarm',
          { index: index },
          { root: true }
        )
      }
    }
    await store.dispatch(
      'pushAlarm/addPushAlarm',
      { pushAlarm: pushAlarm },
      { root: true }
    )
    // 브라우저 notification
    // const notification = new Notification(title, options)
    // return notification
  })

  window.fnFirebaseMessagingGetToken = async () => {
    try {
      return await messaging.getToken()
    } catch (e) {
      console.log(
        'No Instance ID token available. Request permission to generate one.'
      )
    }
  }
} catch (e) {
  console.error(e)
}
