import ServiceExec from '@/common/utils/ServiceExec'
import { forEach } from 'lodash'
/**
 * Common API Service
 * @class SignSvc
 */
class MyPageSvc extends ServiceExec {
  /**
   * 개인정보 수정 – 휴대폰 인증번호 전송
   * @param params
   * @returns {*}
   */
  postMyUserInfoSendAuthValue(params = {}) {
    return this.post(`/api/myPage/myUserInfo/sendAuthValue`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 개인정보 수정 – 비밀번호 유무 체크
   * @param params
   * @returns {*}
   */
  postMyUserInfoHavePwYn(params = {}) {
    return this.post(`/api/myPage/myUserInfo/havePwYn`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 개인정보 확인
   * @param params
   * @returns {*}

   */
  postMyUserInfoDetail(params = {}) {
    return this.post(`/api/myPage/myUserInfo/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 개인정보 수정
   * @param params
   * @returns {*}

   */
  postMyUserInfoUpdate(params = {}) {
    let formData = new FormData()
    forEach(params, function(value, key) {
      if (key === 'file' && typeof value === 'object') {
        formData.append(key, value, value.name)
      } else {
        formData.append(key, value)
      }
    })
    return this.multiPost(`/api/myPage/myUserInfo/update`, formData).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 회원 탈퇴 전 체크
   * @param params
   * @returns {*}

   */
  postWithdrawalCheck(params = {}) {
    return this.post(`/api/myPage/myUserInfo/withdrawal/check`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 회원 탈퇴
   * @param params
   * @returns {*}

   */
  postWithdrawal(params = {}) {
    return this.post(`/api/myPage/myUserInfo/withdrawal`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 생성한 파티 리스트 (검색포함)
   * @param params
   * @returns {*}

   */
  postCreatePartyList(params = {}) {
    return this.post(`/api/myPage/createParty/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 재생성
   * @param params
   * @returns {*}

   */
  postPartyRegenerate(params = {}) {
    return this.post(`/api/myPage/party/regenerate`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 삭제
   * @param params
   * @returns {*}

   */
  postPartyDelete(params = {}) {
    return this.post(`/api/myPage/party/delete`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 상세페이지
   * @param params
   * @returns {*}

   */
  postPartyDetail(params = {}) {
    return this.post(`/api/myPage/party/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 편집시 기존정보 (카테고리, 모집인원, 이용기간 등)
   * @param params
   * @returns {*}

   */
  postPartyInfo(params = {}) {
    return this.post(`/api/myPage/party/info`, params).then(response => {
      return response.data
    })
  }
  /**
   * 생성한 파티 편집
   * @param params
   * @returns {*}

   */
  postPartyUpdate(params = {}) {
    return this.post(`/api/myPage/party/update`, params).then(response => {
      return response.data
    })
  }

  /**
   * 메인페이지 메인
   * @param params
   * @returns {*}

   */
  postMyMain(params = {}) {
    return this.post(`/api/myPage/myMain`, params).then(response => {
      return response.data
    })
  }

  /**
   * 참여한 파티 리스트 (검색포함)
   * @param params
   * @returns {*}

   */
  postParticipatePartyList(params = {}) {
    return this.post(`/api/myPage/participateParty/list`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 참여한 파티 상세페이지
   * @param params
   * @returns {*}

   */
  postParticipatePartyDetail(params = {}) {
    return this.post(`/api/myPage/participateParty/detail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 패널티 게시판 리스트 (검색포함)
   * @param params
   * @returns {*}

   */
  postPenalty(params = {}) {
    return this.post(`/api/myPage/penalty`, params).then(response => {
      return response.data
    })
  }

  /**
   * 위즈톡 리스트 (마이페이지)
   * @param params
   * @returns {*}

   */
  postChatList(params = {}) {
    return this.post(`/api/myPage/chat/list`, params).then(response => {
      return response.data
    })
  }

  /**
   * 위즈톡 삭제 (마이페이지)
   * @param params
   * @returns {*}

   */
  postChatDelete(params = {}) {
    return this.post(`/api/myPage/chat/delete`, params).then(response => {
      return response.data
    })
  }

  /**
   * 포인트 리스트 (검색포함)
   * @param params
   * @returns {*}

   */
  postMyWizzPointHistory(params = {}) {
    return this.post(`/api/myPage/mywizzpoint/history`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 생성한 파티 멤버 상세페이지
   * @param params
   * @returns {*}

   */
  postPartyMemberDetail(params = {}) {
    return this.post(`/api/myPage/party/member/detail`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 캐시 리스트 (검색포함)
   * @param params
   * @returns {*}

   */
  postMyWizzCashCashHistory(params = {}) {
    return this.post(`/api/myPage/myWizzCash/cashHistory`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 출금 계좌 정보
   * @param params
   * @returns {*}

   */
  postMyWizzCashWithdrawAccountInfo(params = {}) {
    return this.post(`/api/myPage/myWizzCash/withdrawAccountInfo`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 파티 알림 설정 정보
   * @param params
   * @returns {*}

   */
  postMyPartyHopeDetail(params = {}) {
    return this.post(`/api/myPage/myPartyHopeDetail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 캐시 출금 신청 비밀번호 확인
   * @param params
   * @returns {*}

   */
  postMyUserInfoCheckPassword(params = {}) {
    return this.post(`/api/myPage/myUserInfo/checkPassword`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 캐시 출금 신청(계좌정보 변경) – 인증번호 전송
   * @param params
   * @returns {*}

   */
  postMyWizzCashWithdrawAuthSendSms(params = {}) {
    return this.post(`/api/myPage/myWizzCash/withdrawAuthSendSms`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 캐시 출금 신청(계좌정보 변경) – 인증번호 확인
   * @param params
   * @returns {*}

   */
  postMyWizzCashWithdrawAuthCompare(params = {}) {
    return this.post(`/api/myPage/myWizzCash/withdrawAuthCompare`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 캐시 출금 신청
   * @param params
   * @returns {*}

   */
  postMyWizzCashInsertWithdraw(params = {}) {
    return this.post(`/api/myPage/myWizzCash/insertWithdraw`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 파티 알림 리스트
   * @param params
   * @returns {*}

   */
  postMyHopeMatchPartyList(params = {}) {
    return this.post(`/api/myPage/myHopeMatchPartyList`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 위즈톡 알림
   * @param params
   * @returns {*}

   */
  postChatNotificationSetting(params = {}) {
    return this.post(`/api/myPage/chat/notification/setting`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 참여한 파티 삭제
   * @param params
   * @returns {*}

   */
  postParticipatePartyDelete(params = {}) {
    return this.post(`/api/myPage/participateParty/delete`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 생성한 파티 마감
   * @param params
   * @returns {*}

   */
  postCreatePartyClose(params = {}) {
    return this.post(`/api/myPage/party/partyClose`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티장 요청정보 저장
   * @param params
   * @returns {*}
   */
  postPartyOptionRegister(params = {}) {
    return this.post(`/api/myPage/participateParty/options`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 파티장 요청정보 업데이트
   * @param params
   * @returns {*}
   */
  postPartyOptionUpdate(params = {}) {
    return this.post(
      `/api/myPage/participateParty/options/update`,
      params
    ).then(response => {
      return response.data
    })
  }

  /**
   * 참여한 파티 연장신청
   * @param params
   * @returns {*}
   */
  postJoinPartyAutoExtension(params = {}) {
    return this.post(`/api/myPage/participateParty/extension`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 참여한 파티 연장신청 취소
   * @param params
   * @returns {*}
   */
  postJoinPartyAutoExtensionCancel(params = {}) {
    return this.post(
      `/api/myPage/participateParty/extension/cancel`,
      params
    ).then(response => {
      return response.data
    })
  }

  /**
   *
   * 생성한 파티 연장신청
   * @param params
   * @returns {*}
   */
  postPartyAutoExtension(params = {}) {
    return this.post(`/api/myPage/party/autoExtension`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 생성한 파티 연장취소
   * @param params
   * @returns {*}
   */
  postPartyAutoExtensionCancel(params = {}) {
    return this.post(`/api/myPage/party/autoExtension/cancel`, params).then(
      response => {
        return response.data
      }
    )
  }

  // 캐시 정산 내역 4.15-1
  postMyWizzCashCashSummary(params = {}) {
    return this.post(`/api/myPage/myWizzCash/cashSummary`, params).then(
      res => res.data
    )
  }

  // 캐시 정산 리스트 4.15-2
  postMyWizzCashCashSummaryList(params = {}) {
    return this.post(`/api/myPage/myWizzCash/cashSummaryList`, params).then(
      res => res.data
    )
  }

  // 캐시 결산 상세 내역 4.15-3
  postMyWizzCashCashSummaryDetail(params = {}) {
    return this.post(`/api/myPage/myWizzCash/cashSummaryDetail`, params).then(
      res => res.data
    )
  }

  // 포인트 정산 내역 4.15-4
  postMyWizzPointPointSummary(params = {}) {
    return this.post(`/api/myPage/mywizzpoint/pointSummary`, params).then(
      res => res.data
    )
  }

  // 캐시 정산 리스트 4.15-5
  postMyWizzPointPointSummaryList(params = {}) {
    return this.post(`/api/myPage/mywizzpoint/pointSummaryList`, params).then(
      res => res.data
    )
  }

  // 캐시 결산 상세 내역 4.15-6
  postMyWizzPointPointSummaryDetail(params = {}) {
    return this.post(`/api/myPage/mywizzpoint/pointSummaryDetail`, params).then(
      res => res.data
    )
  }

  // 파티원 요청 정보 업데이트
  postParticipatePartyUpdateResOption(params = {}) {
    return this.post(
      `/api/myPage/participateParty/updateResOption`,
      params
    ).then(res => res.data)
  }

  // 추천인 코드 요청하기
  postMyUserInfoCreateCode() {
    return this.post(`/api/myPage/myUserInfo/createCode`).then(res => res.data)
  }
}
export default new MyPageSvc()
