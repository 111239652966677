<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <!--<div class="con_ttl center color">
                    <span><br>공지사항<br><br></span>
                </div>-->
                <div class="center">
                  <p>
                    안녕하세요!<br />
                    현명한 공유생활의 시작 위즈니입니다.<br />
                  </p>
                  <br />
                  <p>
                    현재 위즈니는 서비스 안정화(오픈베타)기간 중이며 모바일 및
                    다양한 서비스를 준비중에 있습니다.<br />
                    서비스 안정화 기간중 이용에 불편사항이나 개선사항이 있으시면
                    1:1 문의를 꼭 남겨주세요.
                  </p>
                  <br />
                  <p>
                    <strong style="color: #003471;"
                      >위즈니는 개인간 계정 및 쿠폰 등을 안전하게 거래할 수
                      있도록 에스크로 서비스를 기본으로 제공하고
                      있습니다.</strong
                    ><br />
                    따라서
                    <strong style="color: #EE0000; font-weight: 500;"
                      >위즈니 서비스를 이용하지 않고 판매자와 직접 거래하여
                      발생되는 문제에 대해서는 책임지지 않습니다.</strong
                    >
                  </p>
                  <br />
                  <p>
                    앞으로도 항상 발전하는 위즈니가 되겠습니다.<br />
                    <br />
                    감사합니다.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnIsShowMainNoti(0)">
                그만보기
              </button>
              <button class="btn" @click="fnIsShowMainNoti(1)">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-main-noti',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
