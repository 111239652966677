import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    item: { ...data }
  })
  const fnMove = async () => {
    callback()
    proxy.$emit('close')
  }
  return { ...toRefs(state), fnMove }
}
