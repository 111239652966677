<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span
                    >위즈니 {{ typeName === 'PARTY' ? '구독' : '마켓' }} 환불
                    정책</span
                  >
                </div>
                <div v-if="typeName === 'PARTY'">
                  <div class="form_ttl sky">환불 진행 절차</div>
                  <div class="discription">
                    <ul>
                      <li>
                        환불 요청은 [마이페이지 - 구독 내역 - 참여 파티]
                        페이지에서 가능하며, 구매 후 언제든지 환불 요청이
                        가능합니다.
                      </li>
                      <li>
                        파티장이 계정을 등록한 경우 계정 확인 시 단순 변심으로
                        인한 환불은 불가능합니다.
                      </li>
                      <li>
                        파티장이 환불 요청에 대해 무응답일 경우 취소 요청 기준
                        3일 뒤 자동으로 환불 처리됩니다.
                      </li>
                      <li>
                        자동취소될 경우 카드 취소처리 되거나 캐시로 정산되며,
                        처리는 영업일 기준 3~5일 소요됩니다.
                      </li>
                      <li>
                        포인트 환급은 카드 취소가 완료된 상태에서 환급됩니다.
                      </li>
                    </ul>
                  </div>
                  <div class="form_ttl red">환불 불가</div>
                  <div class="discription">
                    <ul>
                      <li>
                        파티장이 주의사항에 환불이 불가능한 사유에 대해 고지했을
                        경우 환불이 불가능합니다.
                      </li>
                      <li>
                        파티장이 등록한 계정에 문제가 없고, 계정 정보를
                        확인하였다면 환불이 불가능합니다.
                      </li>
                      <li>
                        다음과 같은 상황은 예외적으로 환불을 요청할 수
                        있습니다.(고지된 내용과 다르거나 파티의 하자가 있는
                        경우, 사기가 의심된 경우)
                      </li>
                    </ul>
                  </div>
                </div>
                <div v-else-if="typeName === 'MARKET'">
                  <div class="form_ttl sky">환불 진행 절차</div>
                  <div class="discription">
                    <ul>
                      <li>
                        환불 요청은 [마이페이지 - 마켓내역 - 구매 -
                        구매내역상세정보] 페이지에서 가능하며, 구매 확정 전까지
                        요청 가능합니다.(구매 후 3일 뒤 자동 구매 확정
                        처리됩니다.)
                      </li>
                      <li>
                        판매자가 제공한 비밀 정보(핀번호 포함)확인시 단순
                        변심으로 인한 환불 처리는 불가능합니다.
                      </li>
                      <li>
                        판매자가 환불 요청에 대해 무응답일 경우 환불 요청 기준
                        3일 뒤 자동 환불 처리됩니다.
                      </li>
                      <li>
                        자동취소될 경우 카드 취소처리 되거나 캐시로 정산되며,
                        처리는 영업일 기준 3~5일 소요됩니다.
                      </li>
                      <li>
                        포인트 환급은 카드 취소가 완료된 상태에서 환급됩니다.
                      </li>
                    </ul>
                  </div>
                  <div class="form_ttl sky">환불 불가</div>
                  <div class="discription">
                    <ul>
                      <li>
                        판매자가 주의사항에 환불이 불가능한 상품에 대한 내용을
                        고지했을 경우 환불이 불가능합니다.(핀 번호 또는 바코드,
                        기타 인증번호가 노출되는 쿠폰)
                      </li>
                      <li>
                        구매자가 상품을 사용했을 경우는 환불이 불가능합니다.
                      </li>
                      <li>이미 구매확정이 된 상품은 환불이 불가능합니다.</li>
                      <li>
                        다음과 같은 상황은 예외적으로 환불을 요청할 수
                        있습니다.(고지된 내용과 다르거나 상품에 하자가 있는
                        경우, 사기가 의심된 경우)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn" @click="() => $emit('close')">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-refund-policy',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>
