import { createRouter, createWebHistory } from 'vue-router'
import ConstCode from '@/common/constants/ConstCode'
import Util from '@/common/utils/Util'
import store from '@/store'
import UserSvc from '@/common/service/UserSvc'
// import { fnModalAlert } from '@/components/common/modal/alert'
// import MAIN_NOTI from '@/components/common/modal/alert/main-noti/index.vue'

const updateRouteLayout = async meta => {
  await store.dispatch('layout/updateRouteLayout', {
    isHeader: meta.isHeader,
    isBack: meta.isBack,
    isNav: meta.isNav,
    isFooter: meta.isFooter,
    isLogin: meta.isLogin
  })
}

const routes = [
  {
    path: '/',
    name: 'root',
    beforeEnter: async function(to, from, next) {
      const userData = store.getters['user/getData']
      if (!Util.isEmpty(userData)) {
        const isAuto = store.getters['user/getIsAuto']
        if (isAuto !== ConstCode.BOOLEAN_VALUE.TRUE) {
          // await FcmSvc.postUnregister()
          await UserSvc.postLogout({ id: userData.userId })
        }
      }
      // // INFO: 클로즈베타기간 모달 노출
      // const isShowMainNoti = store.getters['user/getIsShowMainNoti']
      // if (Util.isEmpty(isShowMainNoti) || isShowMainNoti > 0) {
      //   setTimeout(async () => {
      //     const payload = {
      //       component: MAIN_NOTI,
      //       data: {},
      //       callback: async () => {}
      //     }
      //     await fnModalAlert(payload)
      //   }, 500)
      // }
      next({ path: '/main', query: { isRoot: 1 } })
    }
  },
  {
    path: '/hub',
    name: 'hub',
    component: () => import('@/views/hub/index.vue')
  },
  {
    path: '/main',
    name: 'main-index',
    component: () => import('@/views/main/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/main/search',
    name: 'main-search-index',
    component: () => import('@/views/main/search/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/trading/:type/:productNo',
    name: 'trading-index',
    component: () => import('@/views/trading/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/login',
    name: 'login-index',
    component: () => import('@/views/login/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/login/mail',
    name: 'login-mail-index',
    component: () => import('@/views/login/mail/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: true
    },
    props: true
  },
  {
    path: '/oauth',
    name: 'oauth-index',
    component: () => import('@/views/oauth/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/noti',
    name: 'regist-noti-index',
    component: () => import('@/views/regist/noti/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/terms',
    name: 'regist-terms-index',
    component: () => import('@/views/regist/terms/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/form',
    name: 'regist-form-index',
    component: () => import('@/views/regist/form/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/recommend',
    name: 'regist-recommend-index',
    component: () => import('@/views/regist/recommend/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/done',
    name: 'regist-done-index',
    component: () => import('@/views/regist/done/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/success',
    name: 'regist-success-index',
    component: () => import('@/views/regist/success/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/fail',
    name: 'regist-fail-index',
    component: () => import('@/views/regist/fail/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/regist/mail',
    name: 'regist-mail-index',
    component: () => import('@/views/regist/mail/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: true
    },
    props: true
  },
  {
    path: '/find/id',
    name: 'find-id-index',
    component: () => import('@/views/find/id/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    },
    children: [
      {
        path: 'form',
        name: 'find-id-form-index',
        component: () => import('@/components/views/find/id/form/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: false
        }
      },
      {
        path: 'done',
        name: 'find-id-done-index',
        component: () => import('@/components/views/find/id/done/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: false
        },
        props: true
      }
    ]
  },
  {
    path: '/find/password',
    name: 'find-password-index',
    component: () => import('@/views/find/password/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    },
    children: [
      {
        path: 'id-check',
        name: 'find-password-id-check-index',
        component: () =>
          import('@/components/views/find/password/id-check/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: false
        }
      },
      {
        path: 'auth-type',
        name: 'find-password-auth-type-index',
        component: () =>
          import('@/components/views/find/password/auth-type/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: false
        },
        props: true
      }
    ]
  },
  {
    path: '/find/password/form',
    name: 'find-password-form-index',
    component: () => import('@/views/find/password/form/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    },
    props: true
  },
  {
    path: '/find/password/done',
    name: 'find-password-done-index',
    component: () => import('@/views/find/password/done/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    },
    props: true
  },
  {
    path: '/party/list',
    name: 'party-list-index',
    component: () => import('@/views/party/list/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/party/write',
    name: 'party-write-index',
    component: () => import('@/views/party/write/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/party/view',
    name: 'party-view-index',
    component: () => import('@/views/party/view/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/party/pay',
    name: 'party-pay-index',
    component: () => import('@/views/party/pay/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/party/done',
    name: 'party-done-index',
    component: () => import('@/views/party/done/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/party/search',
    name: 'party-search-index',
    component: () => import('@/views/party/search/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/cs',
    name: 'cs-index',
    component: () => import('@/views/cs/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    },
    children: [
      {
        path: 'terms',
        name: 'cs-terms-index',
        component: () => import('@/views/cs/terms/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'notice',
        name: 'cs-notice-index',
        component: () => import('@/views/cs/notice/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'faq',
        name: 'cs-faq-index',
        component: () => import('@/views/cs/faq/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'my-question',
        name: 'cs-my-question-index',
        component: () => import('@/views/cs/my-question/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'report',
        name: 'cs-report-index',
        component: () => import('@/views/cs/report/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'notice/view',
        name: 'cs-notice-view-index',
        component: () => import('@/views/cs/notice/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'my-question/write',
        name: 'cs-my-question-write-index',
        component: () => import('@/views/cs/my-question/write/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'my-question/view',
        name: 'cs-my-question-view-index',
        component: () => import('@/views/cs/my-question/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'report/write',
        name: 'cs-report-write-index',
        component: () => import('@/views/cs/report/write/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'report/view',
        name: 'cs-report-view-index',
        component: () => import('@/views/cs/report/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: false,
          isFooter: false,
          isLogin: true
        }
      }
    ]
  },
  // {
  //   path: '/community/wizzpedia',
  //   name: 'community-wizzpedia-index',
  //   component: () => import('@/views/community/wizzpedia/index.vue'),
  //   meta: {
  //     isHeader: true,
  //     isBack: false,
  //     isNav: true,
  //     isFooter: false,
  //     isLogin: true
  //   }
  // },
  {
    path: '/community/wizzpedia/search',
    name: 'community-wizzpedia-search-index',
    component: () => import('@/views/community/wizzpedia/search/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/community/wizzpedia/view',
    name: 'community-wizzpedia-view-index',
    component: () => import('@/views/community/wizzpedia/view/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/community/party-hope',
    name: 'community-party-hope-index',
    component: () => import('@/views/community/party-hope/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/community/party-score',
    name: 'community-party-score-index',
    component: () => import('@/views/community/party-score/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/community/party-score/search',
    name: 'community-party-score-search-index',
    component: () => import('@/views/community/party-score/search/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/my-page',
    name: 'my-page-index',
    component: () => import('@/views/my-page/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    },
    children: [
      {
        path: 'home',
        name: 'my-page-home-index',
        component: () => import('@/views/my-page/home/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'party-noti',
        name: 'my-page-party-noti-index',
        component: () => import('@/views/my-page/party-noti/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'party-noti/write',
        name: 'my-page-party-noti-write-index',
        component: () => import('@/views/my-page/party-noti/write/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'wizz-talk',
        name: 'my-page-wizz-talk-index',
        component: () => import('@/views/my-page/wizz-talk/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'joined-party',
        name: 'my-page-joined-party-index',
        component: () => import('@/views/my-page/joined-party/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'created-party',
        name: 'my-page-created-party-index',
        component: () => import('@/views/my-page/created-party/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'created-party/member',
        name: 'my-page-created-party-member-index',
        component: () =>
          import('@/views/my-page/created-party/member/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'adjustment',
        name: 'my-page-adjustment-index',
        component: () => import('@/views/my-page/adjustment/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'adjustment/:name/:dt',
        name: 'my-page-adjustment-detail-index',
        component: () => import('@/views/my-page/adjustment/detail/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'cash-charge/report',
        name: 'my-page-cash-charge-report-index',
        component: () => import('@/views/my-page/cash-charge/report/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'cash-point/withdraw',
        name: 'my-page-cash-point-withdraw-index',
        component: () =>
          import('@/views/my-page/cash-point/withdraw/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'penalty',
        name: 'my-page-penalty-index',
        component: () => import('@/views/my-page/penalty/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market',
        name: 'my-page-market-index',
        component: () => import('@/views/my-page/market/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      // {
      //   path: 'wizz-talk/market',
      //   name: 'my-page-wizz-talk-market-index',
      //   component: () => import('@/views/my-page/wizz-talk/market/index.vue'),
      //   meta: {
      //     isHeader: true,
      //     isBack: true,
      //     isNav: true,
      //     isFooter: false,
      //     isLogin: true
      //   }
      // },
      {
        path: 'score',
        name: 'my-page-score-index',
        component: () => import('@/views/my-page/score/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/member',
        name: 'my-page-market-member-index',
        component: () => import('@/views/my-page/market/member/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'created-party/view',
        name: 'my-page-created-party-view-index',
        component: () => import('@/views/my-page/created-party/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'joined-party/view',
        name: 'my-page-joined-party-view-index',
        component: () => import('@/views/my-page/joined-party/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'created-party/edit',
        name: 'my-page-created-party-edit-index',
        component: () => import('@/views/my-page/created-party/edit/index.vue'),
        props: true,
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/view',
        name: 'my-page-market-view-index',
        component: () => import('@/views/my-page/market/view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/view/detail',
        name: 'my-page-market-view-detail-index',
        component: () => import('@/views/my-page/market/view/detail/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/view/detail/:prev',
        name: 'my-page-market-view-detail-before-buyer-index',
        component: () =>
          import('@/views/my-page/market/view/detail/before-buyer/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/buyer-view',
        name: 'my-page-market-buyer-view-index',
        component: () => import('@/views/my-page/market/buyer-view/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'market/buyer-view/detail',
        name: 'my-page-market-buyer-view-detail-index',
        component: () =>
          import('@/views/my-page/market/buyer-view/detail/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        },
        props: true
      },
      {
        path: 'market/edit',
        name: 'my-page-market-edit-index',
        component: () => import('@/views/my-page/market/edit/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        },
        props: true
      },
      {
        path: 'cash-charge',
        name: 'my-page-cash-charge-index',
        component: () => import('@/views/my-page/cash-charge/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      },
      {
        path: 'cash-charge/done',
        name: 'my-page-cash-charge-done-index',
        component: () => import('@/views/my-page/cash-charge/done/index.vue'),
        meta: {
          isHeader: true,
          isBack: true,
          isNav: true,
          isFooter: false,
          isLogin: true
        }
      }
    ]
  },
  {
    path: '/my-info/phone-auth',
    name: 'my-info-phone-auth-index',
    component: () => import('@/views/my-info/phone-auth/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/my-info/password',
    name: 'my-info-password-index',
    component: () => import('@/views/my-info/password/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/my-info/view',
    name: 'my-info-view-index',
    component: () => import('@/views/my-info/view/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/my-info/success',
    name: 'my-info-success-index',
    component: () => import('@/views/my-info/success/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/my-info/fail',
    name: 'my-info-fail-index',
    component: () => import('@/views/my-info/fail/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/settle/next',
    name: 'settle-next-index',
    component: () => import('@/views/settle/next/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/settle/cancel',
    name: 'settle-cancel-index',
    component: () => import('@/views/settle/cancel/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/wizz-talk',
    name: 'wizz-talk-index',
    component: () => import('@/views/wizz-talk/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/market/list',
    name: 'market-list-index',
    component: () => import('@/views/market/list/index.vue'),
    meta: {
      isHeader: true,
      isBack: false,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/market/write',
    name: 'market-write-index',
    component: () => import('@/views/market/write/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/market/view',
    name: 'market-view-index',
    component: () => import('@/views/market/view/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/market/pay',
    name: 'market-pay-index',
    component: () => import('@/views/market/pay/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    },
    props: true
  },
  {
    path: '/market/done',
    name: 'market-done-index',
    component: () => import('@/views/market/done/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: false,
      isFooter: false,
      isLogin: true
    }
  },
  {
    path: '/market/wizz-talk',
    name: 'market-wizz-talk-index',
    component: () => import('@/views/market/wizz-talk/index.vue'),
    meta: {
      isHeader: false,
      isBack: false,
      isNav: false,
      isFooter: false,
      isLogin: false
    }
  },
  {
    path: '/market/search',
    name: 'market-search-index',
    component: () => import('@/views/market/search/index.vue'),
    meta: {
      isHeader: true,
      isBack: true,
      isNav: true,
      isFooter: false,
      isLogin: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const fromHistory = Boolean(savedPosition)

    if (fromHistory && store.state.routerHistory.length > 0) {
      store.state.routerHistory.splice(-1, 1)
    } else {
      store.state.routerHistory.push(from)
    }

    return { left: 0, top: 0 }
  }
})

const signInPages = [
  'write',
  'edit',
  'my-page',
  'my-question',
  'report',
  'wizz-talk'
]

router.beforeEach(async (to, from, next) => {
  let meta = to.meta
  const userData = store.getters['user/getData']
  if (Util.isEmpty(userData)) {
    if (to.name && signInPages.some(word => to.name.includes(word))) {
      alert('페이지 접속 시 로그인이 필요합니다.')
      await store.dispatch('user/signOut')
      next({ name: 'login-index' })
      return
    }
  } else {
    const authorization = store.getters['user/getAuthorization']
    if (Util.isEmpty(authorization)) {
      // alert 안 보이게 220114 수정
      // alert('토큰이 만료되었습니다. 재로그인 해주세요.')  
      await store.dispatch('user/signOut')
      next({ name: 'login-index' })
      return
    } else {
      const decodeToken = Util.jwtDecode(authorization)
      if (Date.now() > new Date(decodeToken.exp * 1000)) {
        // alert 안 보이게 220114 수정
        // alert('토큰이 만료되었습니다. 재로그인 해주세요.')
        await store.dispatch('user/signOut')
        next({ name: 'login-index' })
        return
      }
    }
  }
  await updateRouteLayout(meta)
  next()
})

export default router
