import { getCurrentInstance } from 'vue'

export const componentState = ({ callback }) => {
  const { proxy } = getCurrentInstance()
  const modalClose = e => {
    if (e.target.id !== 'modalValue') {
      proxy.$emit('close')
    }
  }

  callback()

  return { modalClose }
}
