import {
  reactive,
  computed,
  toRefs,
  getCurrentInstance,
  onMounted,
  ref
} from 'vue'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    img: props.data.img,
    canvas: undefined,
    base64: '',
    file: undefined,
    aspectRatio: computed(() => {
      if (proxy.$Util.isEmpty(props.data.aspectRatio)) return 1
      return props.data.aspectRatio
    })
  })
  const change = ({ canvas }) => {
    state.canvas = canvas
  }
  const fnCallBack = event => {
    state.base64 = state.canvas.toDataURL()
    const blobBin = atob(state.base64.split(',')[1])
    const array = []
    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i))
    }
    const imgType = props.data.fileName.split('.').pop()
    state.file = new Blob([new Uint8Array(array)], { type: `image/${imgType}` })
    state.file.name = props.data.fileName
    const maxSize = 3 * 1024 * 1024
    if (state.file.size > maxSize)
      return alert('설정한 크기가 너무 큽니다 다시 설정해주세요!')
    props.callback(state.base64, state.file, event)
    proxy.$emit('close')
  }

  const cropper = ref(null)
  onMounted(() => {
    if (!proxy.$Util.isEmpty(state.aspectRatio)) {
      cropper.value.refresh()
    }
  })

  return { ...toRefs(state), change, fnCallBack, cropper }
}
