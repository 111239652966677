<template>
  <header class="header">
    <div class="inner">
      <div class="left"></div>
      <h1 class="logo">
        <a href="javascript:return"
          ><img src="@/assets/static/images/logo_header.svg" alt="위즈니"
        /></a>
      </h1>
      <div class="right">
        <button class="h_btn btn_skip" @click="$emit('close')">skip</button>
      </div>
    </div>
  </header>
  <main class="main" role="main">
    <div class="sv_slide">
      <swiper
        tag="ul"
        class="sv_slide_ul"
        :slides-per-view="1"
        :centered-slides="true"
        :speed="500"
        :autoplay="{ delay: 3000 }"
        @slideChange="onSlideChange"
      >
        <swiper-slide
          tag="li"
          class="sv_slide_li"
          v-for="(item, index) in items"
          :key="index"
          ><div class="img_area">
            <img :src="item" alt="서비스 안내 배너" /></div
        ></swiper-slide>
        <swiper-slide tag="li" class="sv_slide_li" :key="items.length">
          <div class="img_area">
            <div class="sv_slide_con">
              <div class="inform center">
                <div>
                  <span class="color">이제 위즈니를 시작해 보세요.</span>
                </div>
              </div>
              <div class="con_btn">
                <div class="btn_wrap">
                  <button class="btn" @click="fnPage">
                    {{ $Util.isEmpty(userData) ? '회원가입' : '메인' }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="pager">
        <ul>
          <li
            :class="{ on: activeIndex === index }"
            v-for="(item, index) in items"
            :key="index"
          ></li>
          <li :class="{ on: activeIndex === 3 }"></li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-service-info',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
