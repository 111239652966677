import { computed, getCurrentInstance, reactive, toRefs } from 'vue'
// import { useRoute } from 'vue-router'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  // const route = useRoute()
  const state = reactive({
    party: { ...data },
    diffDay: computed(() => {
      if (proxy.$Util.isEmpty(state.party)) return 0
      const startDate = proxy.$dayjs(state.party.regDt).format('YYYY-MM-DD')
      const doneDate = proxy.$dayjs(state.party.useDt).format('YYYY-MM-DD')
      return proxy.$dayjs(doneDate).diff(proxy.$dayjs(startDate), 'day')
    }),
    totalPrice: computed(() => {
      if (proxy.$Util.isEmpty(state.party)) return 0
      if (state.party.acntTransferYn === 'N') {
        return state.diffDay * Number(state.party.dailyPrice)
      } else {
        return Number(state.party.dailyPrice)
      }
    })
  })
  const fnExtension = async () => {
    await callback(1)
    proxy.$emit('close')
  }
  const fnCancel = async () => {
    await proxy.$MainSvc.postExtensionNotificationOff({
      partyNo: state.party.partyNo
    })
    // if (res.resultCode !== '0000') {
    //   alert(res.resultMsg)
    // }
    if (data.isExtensionConfirm !== 1) {
      await callback(-1)
    }
    proxy.$emit('close')
  }
  return { ...toRefs(state), fnExtension, fnCancel }
}
