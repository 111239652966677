import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    useDt: data.useDt || '',
    exNum: data.exNum || '',
    pin: data.pin || '',
    isEdit: data.isEdit || false
  })

  const fnSave = async () => {
    if (!isValid()) return
    let aPinItem
    if (state.isEdit) {
      aPinItem = { ...data, ...state }
    } else {
      aPinItem = { ...state }
    }
    delete aPinItem.isEdit
    proxy.$emit('close')
    callback(aPinItem)
  }

  const maskingPinText = e => {
    e.target.value = e.target.value.replace(/[^a-z0-9]/gi, '')
    state.pin = e.target.value
  }

  const maskingExNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    state.exNum = e.target.value
  }

  const isValid = () => {
    if (proxy.$Util.isEmpty(state.pin)) {
      alert('PIN 번호를 입력해주세요.')
      return false
    }
    if (!proxy.$Util.isEmpty(state.useDt)) {
      if (proxy.$dayjs().diff(state.useDt, 'day') > 0) {
        alert('사용 기간은 과거일로 설정할 수 없습니다.')
        return false
      }
    }
    return true
  }

  return {
    ...toRefs(state),
    maskingExNumber,
    maskingPinText,
    fnSave
  }
}
