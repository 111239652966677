import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = ({ data }) => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    item: { ...data }
  })

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: data.marketNo
      }
    })
  }

  const fnRegenerate = async () => {
    if (!confirm('재등록 하시겠습니까?')) return
    await proxy.$emit('close')
    await router.push({
      name: 'my-page-market-edit-index',
      params: { regenerate: true },
      query: { marketNo: data.marketNo }
    })
  }

  return { ...toRefs(state), fnEdit, fnRegenerate }
}
