<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>취소/환불 요청사항</span>
                </div>
                <div class="hint">* 취소/ 환불 사유를 입력해 주세요.</div>
                <div class="hint">
                  * 위즈니 관리자가 해당 사항을 심사하는 동안 파티장의 캐시
                  지급은 일시 중지됩니다.
                </div>
                <div class="hint">
                  * 심사는 영업일 기준 3일 정도 소요되며, 파티장의 과실이
                  확인되면 사용일을 제한 잔액을 위즈 캐시로 환불합니다.
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_in">
                          <div class="inline">
                            <label class="radio" v-if="data.simpleChangeMind">
                              <input
                                type="radio"
                                name="reason"
                                v-model="form.option"
                                :value="0"
                              />
                              <span class="label"><span>단순 변심</span></span>
                            </label>
                            <label class="radio">
                              <input
                                type="radio"
                                name="reason"
                                v-model="form.option"
                                :value="1"
                              />
                              <span class="label"
                                ><span>파티장 과실</span></span
                              >
                            </label>
                          </div>
                        </div>
                      </li>
                      <li class="form_li">
                        <div class="form_in">
                          <label>
                            <textarea
                              placeholder="500자 이내로 작성"
                              :maxlength="500"
                              v-model="form.msg"
                            ></textarea>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-refund',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
