<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span
                    >{{ data.type === 'PARTY' ? '파티원' : '구매자' }} 요청정보
                    수정</span
                  >
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li v-if="resOptions.resKakao" class="form_li">
                        <div class="form_ttl essen">카카오톡 ID</div>
                        <div class="form_in">
                          <label class="input">
                            <input
                              type="text"
                              placeholder="카카오톡 ID를 입력해주세요"
                              @input="maskingResKakao"
                              :value="resKakao"
                              :maxlength="100"
                            />
                          </label>
                        </div>
                      </li>
                      <li v-if="resOptions.resPhone" class="form_li">
                        <div class="form_ttl essen">휴대폰번호</div>
                        <div class="form_in">
                          <label class="input">
                            <input
                              type="text"
                              placeholder="휴대폰번호를 입력해 주세요"
                              @input="maskingResPhone"
                              :value="resPhone"
                              :maxlength="11"
                            />
                          </label>
                        </div>
                      </li>
                      <li v-if="resOptions.resEmail" class="form_li">
                        <div class="form_ttl essen">이메일</div>
                        <div class="form_in">
                          <label class="input">
                            <input
                              type="text"
                              placeholder="이메일을 입력해주세요"
                              @input="maskingResEmail"
                              :value="resEmail"
                              :maxlength="100"
                            />
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">수정</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-req-option-edit',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
