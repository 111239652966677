import store from '@/store'
import dayjs from 'dayjs'

class Util {
  constructor() {
    this.store = store
  }
  isEmpty = value => {
    return (
      value === '' ||
      value === null ||
      value === undefined ||
      (value != null && typeof value === 'object' && !Object.keys(value).length)
    )
  }

  isMobile = () => {
    return navigator.userAgent.toLowerCase().indexOf('mobi') > -1
  }

  isValidPassword = value => {
    if (this.isEmpty(value)) return false
    const regExp1 = /[0-9]/
    const regExp2 = /[a-zA-Z]/
    // eslint-disable-next-line no-useless-escape
    const regExp3 = /['"~․!@#$%^&*()_\-+=\[\]\[\]|;:‘“<>,.?\\/{}]/
    if (
      !regExp1.test(value) ||
      !regExp2.test(value) ||
      !regExp3.test(value) ||
      value.length < 8 ||
      value.length > 12
    ) {
      return false
    }
    return true
  }

  isValidNick = (value, min = 1, max = 10) => {
    if (this.isEmpty(value)) return false
    if (
      this.isValidHangle(value, min, max) ||
      this.isValidAlphaAndNumber(value, min, max) ||
      this.isValidSpc(value, min, max)
    ) {
      return false
    }
    return true
  }

  isValidHangle = (value, min, max) => {
    const regExp = /[가-힣]/
    if (!regExp.test(value) || value.length < min || value.length > max) {
      return false
    }
    return true
  }

  isValidAlphaAndNumber = (value, min, max) => {
    const regExp = /[a-zA-Z0-9]/
    if (!regExp.test(value) || value.length < min || value.length > max) {
      return false
    }
    return true
  }
  isValidSpc = (value, min, max) => {
    const regExp = /[~!@#$%^&*()_+|<>?:{}]/
    if (!regExp.test(value) || value.length < min || value.length > max) {
      return false
    }
    return true
  }

  isValidEmail = value => {
    let regExp = /^([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
    return regExp.test(value)
  }

  /**
   * 핸드폰 하이픈(-) 포맷방식 출력 (type : 0일경우, 가운데 자리 **** 표시)
   * @param num
   * @param type
   * @returns {*|string}
   */
  getPhoneFormat(num, type = 2) {
    let formatNum = ''
    try {
      if (num.length === 11) {
        if (type === 0) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3')
        } else if (type === 1) {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-****')
        } else {
          formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
        }
      } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2')
      } else {
        if (num.indexOf('02') === 0) {
          if (num.length === 10) {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3')
            } else if (type === 1) {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-****')
            } else {
              formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-****-$3')
            } else if (type === 1) {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-****')
            } else {
              formatNum = num.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3')
            }
          }
        } else {
          if (num.length === 10) {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3')
            } else if (type === 1) {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-****')
            } else {
              formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
            }
          } else {
            if (type === 0) {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-***-$3')
            } else if (type === 1) {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-****')
            } else {
              formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
            }
          }
        }
      }
    } catch (e) {
      formatNum = num
      console.log(e)
    }
    return formatNum
  }

  /**
   * 만나이 계산
   * @param birth
   * @returns {number}
   */
  getBirthAge(birth) {
    let today = new Date()
    let age = today.getFullYear() - birth.getFullYear()
    birth.setFullYear(today.getFullYear())
    if (today < birth) age--
    return age
  }

  lpad(str, padLen, padStr) {
    if (padStr.length > padLen) {
      console.log('오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다')
      return str
    }
    str += '' // 문자로
    padStr += '' // 문자로
    while (str.length < padLen) str = padStr + str
    str = str.length >= padLen ? str.substring(0, padLen) : str
    return str
  }

  formatNumber2(value, options) {
    let dl = options['decimalLength'] || 0
    let ts = options['thousandsSep'] || ','
    let ty = options['type'] || ''
    let ut = options['unit'] || ''
    if (this.isEmpty(value) || isNaN(value) || value === 0) return `0${ut}`
    if (ty !== '') value = value * 100
    value = parseFloat(value)
    let re = '\\d(?=(\\d{3})+' + (dl > 0 ? '\\D' : '$') + ')'
    let num = value.toFixed(Math.max(0, ~~dl))
    return `${num.replace(new RegExp(re, 'g'), '$&' + ts)}${ut}`
  }

  getRemainDate(deadDate, unit = 'day') {
    const nowStart = dayjs(dayjs().format('YYYY-MM-DD'))
    return dayjs(deadDate).diff(nowStart, unit)
  }

  isNumeric(value) {
    return !isNaN(Number(value))
  }

  jwtDecode(jwt) {
    const infoBase64 = jwt.split('.')[1]
    const payload = Buffer.from(infoBase64, 'base64')
    return JSON.parse(payload.toString())
  }

  getRandomString() {
    return (Math.random() + 1).toString(36).substring(7)
  }
}
export default new Util()
