import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    form: {
      seq: data.seq,
      reply: data.reply || '',
      isEdit: data.isEdit
    }
  })
  const fnSave = async () => {
    if (!isValid()) return
    const params = { ...state.form }
    let res = ''
    if (params.isEdit === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
      res = await proxy.$MarketSvc.postMyPageCancelRefundModifyReply(params)
    } else {
      res = await proxy.$MarketSvc.postMyPageCancelRefundReject(params)
    }

    if (res.resultCode === '0000') {
      alert('취소/환불 반려 되었습니다.')
      callback()
      proxy.$emit('close')
    } else {
      alert(res.resultMsg)
    }
  }
  const init = () => {
    if (!proxy.$Util.isEmpty(data)) {
      proxy.$_.merge(state.form, data)
    }
  }
  const isValid = () => {
    if (proxy.$Util.isEmpty(state.form.reply)) {
      alert('취소 환불 반려사유를 입력해주세요.')
      return false
    }
    return true
  }
  init()
  return { ...toRefs(state), fnSave }
}
