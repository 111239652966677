<template>
  <div class="pt_modal" :style="{ display: 'flex' }">
    <!--이미지 배경색-->
    <div class="pt_modal_con" style="overflow: hidden;">
      <cropper
        style="height: 100%"
        :src="img"
        :stencil-props="{
          aspectRatio: aspectRatio
        }"
        :default-size="{
          width: 640,
          height: 640
        }"
        image-restriction="none"
        @change="change"
        ref="cropper"
      />
    </div>
    <div class="con_btn">
      <p style="width: 100%;"><br /></p>
      <div class="btn_wrap" style="margin-bottom: 0;">
        <button class="btn bo" @click="$emit('close')">취소</button>
        <button class="btn" @click="fnCallBack">저장</button>
      </div>
      <p style="width: 100%;"><br /></p>
    </div>
  </div>
</template>

<script>
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { componentState } from './index.js'

export default {
  name: 'modal-cropper',
  components: { Cropper },
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
