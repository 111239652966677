<template>
  <div @click="modalClose" class="modal_dim on focus">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="barcode_vertical">
          <VueBarcode
            id="modalValue"
            v-if="data.pin"
            :value="data.pin"
            :options="{ width: 2, height: 200 }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'
import VueBarcode from '@chenfengyuan/vue-barcode'

export default {
  name: 'modal-alert-pin-zoom',
  components: { VueBarcode },
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>
