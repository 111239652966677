import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import ConstCode from '@/common/constants/ConstCode'

const chatServer = ConstCode.CODE_ENV_LIST.CHAT_SERVER_URL
let stompClient = null
var chatroomId = ''

/*
 *  웹소켓 접속
 *  - partyNo : 파티번호
 *  - chatroomId : 채팅방 or 거래진행의 UUID
 */
export function connect(type, productNo, chatroomId, receiveFunc) {
  var socket = new SockJS(chatServer + 'chat-websocket')
  stompClient = Stomp.over(socket)
  stompClient.connect({}, function(frame) {
    /*
     *  subscribe 경로
     *  채팅 : /topic/common/[goodsNo]/[chatroomId]
     *
     *  subscribe를 시작하면 해당 경로에서 발행하는 메시지를 받아볼수 있음
     */
    stompClient.subscribe(
      '/topic/' + type + '/' + productNo + '/' + chatroomId,
      function(resultObj) {
        // 메시지가 오면 subscribe의 두번째 파라미터의 function이 실행됨
        var resultJson = JSON.parse(resultObj.body)
        // 받은 메시지를 receiveFunc function으로 json을 넘겨 처리
        receiveFunc(resultJson)
      }
    )
  })
}

export function sendMessage(sendUri, payload) {
  if (stompClient == null) {
    console.log('socket not conncted!')
    return false
  }

  stompClient.send(sendUri, {}, JSON.stringify(payload))
}

// 접속 종료
export function disconnect() {
  if (stompClient != null) {
    stompClient.disconnect()
  }
}


export const connectPromise = () => {
  stompClient = Stomp.over(new SockJS(chatServer + 'chat-websocket'))
  return new Promise(resolve => stompClient && stompClient.connect({}, () => resolve()))
}

export const disconnectPromise = () => {
  return new Promise(resolve => stompClient && stompClient.disconnect(() => resolve()))
}

export const justSendMessage = async (sendUri, body) => {
  stompClient.send(sendUri, {}, JSON.stringify(body))
  return
}