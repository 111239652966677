import { reactive, toRefs, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

export const componentState = ({ data }) => {
  const { proxy } = getCurrentInstance()
  const { dispatch } = useStore()
  const state = reactive({
    item: { ...data }
  })
  const fnIsShowMainNoti = async (value = 1) => {
    await dispatch('user/updateIsShowMainNoti', { isShowMainNoti: value })
    proxy.$emit('close')
  }
  return { ...toRefs(state), fnIsShowMainNoti }
}
