import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class CommonSvc extends ServiceExec {
  /**
   * 공지사항
   * @param params
   * @returns {*}
   */
  postBankList(params = {}) {
    return this.post(`/api/common/bankList`, params).then(response => {
      return response.data
    })
  }

  /**
   * 카테고리 리스트
   * @param params
   * @returns {*}
   */
  postCategoryList(params = {}) {
    return this.post(`/api/common/categoryList`, params).then(response => {
      return response.data
    })
  }
}
export default new CommonSvc()
