import { computed, getCurrentInstance, reactive, toRefs, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { niceForm } from '@/common/form/NiceForm'
import { CommonFunction } from '@/common/CommonFunction.js'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const route = useRoute()
  const { getters, dispatch } = useStore()
  const state = reactive({
    routeLayout: computed(() => getters['layout/getRouteLayout']),
    userData: computed(() => getters['user/getData']),
    myPageBadge: computed(() => getters['myPage/getMyPageBadge']),
    isShowMyMenu: false,
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`,
    platform: computed(() => {
      if (proxy.$Util.isEmpty(state.userData)) return ''
      if (
        Number(state.userData.loginType) ===
        proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.value
      ) {
        return proxy.$ConstCode.SNS_LOGIN_TYPE.KAKAO.enName
      } else if (
        Number(state.userData.loginType) ===
        proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.value
      ) {
        return proxy.$ConstCode.SNS_LOGIN_TYPE.NAVER.enName
      } else if (
        Number(state.userData.loginType) ===
        proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.value
      ) {
        return proxy.$ConstCode.SNS_LOGIN_TYPE.GOOGLE.enName
      }
      return ''
    })
  })
  const fnMain = async () => {
    if (route.path === '/main') {
      location.reload()
    } else {
      await router.push({ path: '/main', query: { isRoot: 1 } })
    }
  }
  const fnLoginPage = async () => {
    await router.push({ path: '/login' })
  }
  const fnBack = () => {
    router.go(-1)
  }
  const fnShowMyMenu = () => {
    state.isShowMyMenu = !state.isShowMyMenu
  }
  const fnCenter = async () => {
    await router.push({ path: '/cs/notice' })
  }
  const { fnMyInfo, fnLogout, fnUpdateAuthType } = CommonFunction(state)
  const fnCallback = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
      await router.push({ path: '/my-info/phone-auth' })
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }

  const fnMyPage = () => router.push({ name: 'my-page-home-index' })

  watch(
    () => route.name,
    async () => {
      if (!proxy.$Util.isEmpty(route.name)) {
        if (route.name.startsWith('my-page')) {
          await dispatch('myPage/updateMyPageBadge', { myPageBadge: 0 })
        }
      }
    }
  )

  return {
    ...toRefs(state),
    fnMain,
    fnLoginPage,
    fnBack,
    fnShowMyMenu,
    fnLogout,
    fnCenter,
    fnMyInfo,
    fnCallback,
    fnMyPage
  }
}
