import { reactive, toRefs, computed, getCurrentInstance, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    tabs: proxy.$ConstCode.MY_PAGE_TABS,
    tabIndex: computed(() => getters['myPage/getTabIndex'])
  })
  const fnTabIndex = async (tab, event) => {
    await router.replace({ path: tab.path })
    if (tab.path.includes('my-page')) {
      fnComputedScroll(event.target)
    }
  }

  const fnComputedScroll = buttonDom => {
    const leftDom = document.querySelector('#left')
    const buttonLeft = buttonDom.getBoundingClientRect().left
    const moveLeft = leftDom.scrollLeft
    let result = buttonLeft > moveLeft ? buttonLeft : moveLeft
    leftDom.scrollLeft = result
  }

  watch(
    () => route.path,
    async val => {
      const tabIndex = proxy.$_.findIndex(
        proxy.$ConstCode.MY_PAGE_TABS,
        item => {
          return val.indexOf(item.path) > -1 || val.indexOf(item.subPath1) > -1
        }
      )
      const tab = proxy.$ConstCode.MY_PAGE_TABS[tabIndex]
      if (!proxy.$Util.isEmpty(tab)) {
        await dispatch('myPage/updateTabIndex', { tabIndex: tab.index })
        setTimeout(() => {
          const buttonDom = document.querySelector('#left .btn_wrap button.on')
          if (buttonDom) {
            fnComputedScroll(buttonDom)
          }
        }, 100)
      }
    },
    { immediate: true }
  )
  return { ...toRefs(state), fnTabIndex }
}
