export const state = {
  tabIndex: 0
}
export const getters = {
  getTabIndex: state => {
    return state.tabIndex
  }
}
export const mutations = {
  setTabIndex(state, { tabIndex }) {
    state.tabIndex = tabIndex
  }
}
export const actions = {
  updateTabIndex({ commit }, { tabIndex = 0 }) {
    commit('setTabIndex', { tabIndex })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
