export const detailForm = {
  loginType: '',
  id: '',
  pw: '',
  phone: '',
  authType: '',
  name: '',
  nick: '',
  profile: '',
  accountNum: '',
  accountHolder: '',
  bankCode: '',
  bankNm: '',
  wizzTalk: 1, // 위즈톡알림
  hopeParty: 1, // 희망파티알림
  partyPostUpdate: 1, // 파티게시글업데이트
  niceCnt: 0,
  isPhoneAuth: 1,
  isNickDupl: 1
}
