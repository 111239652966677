import { reactive, toRefs, getCurrentInstance } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()

  const state = reactive({
    item: { ...data },
    reply: data.reply
  })
  const fnMove = async () => {
    callback()
    proxy.$emit('close')
  }
  const fnSave = async () => {
    if (proxy.$Util.isEmpty(state.reply)) {
      alert('추가정보를 입력해주세요.')
      return false
    }
    console.log(typeof callback)
    callback(state.reply)
    proxy.$emit('close')
  }
  return { ...toRefs(state), fnMove, fnSave }
}
