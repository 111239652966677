export const state = {
  pushAlarms: []
}
export const getters = {
  getPushAlarms: state => {
    return state.pushAlarms
  }
}
export const mutations = {
  setPushAlarms(state, { pushAlarms }) {
    state.pushAlarms = pushAlarms
  }
}
export const actions = {
  addPushAlarm({ state, commit }, { pushAlarm }) {
    state.pushAlarms.push(pushAlarm)
    commit('setPushAlarms', {
      pushAlarms: state.pushAlarms
    })
  },
  popPushAlarm({ state, commit }, { index = 0 }) {
    state.pushAlarms.splice(index, 1)
    commit('setPushAlarms', { pushAlarms: state.pushAlarms })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
