import { getCurrentInstance, reactive, toRefs } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    payType: data.payType,
    amount: data.amount,
    fee: data.fee,
    total: data.total
  })

  const fnConfirm = () => {
    proxy.$emit('close')
    callback()
  }

  return { ...toRefs(state), fnConfirm }
}
