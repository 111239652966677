import { createStore } from 'vuex'
import layout from '@/store/modules/layout/index'
import user from '@/store/modules/user/index'
import nav from '@/store/modules/nav/index'
import party from '@/store/modules/party/index'
import cs from '@/store/modules/cs/index'
import community from '@/store/modules/community/index'
import myPage from '@/store/modules/my-page/index'
import pushAlarm from '@/store/modules/push-alarm/index'
import wizzTalk from '@/store/modules/wizz-talk/index'

export default createStore({
  state: {
    routerHistory: []
  },
  getters: {
    previousRoute: state => {
      const historyLen = state.routerHistory.length
      if (historyLen == 0) return null
      return state.routerHistory
    }
  },
  mutations: {},
  actions: {},
  modules: {
    layout,
    user,
    nav,
    party,
    cs,
    community,
    myPage,
    pushAlarm,
    wizzTalk
  }
})
