<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="party_list_li">
                  <div class="party_tag center big">
                    <!--'중앙: center','큰사이즈: big'-->
                    <div class="left">
                      <div
                        class="pt_tag logo"
                        style="--pt-txt-color: #fff;"
                        :style="{
                          '--pt-bg-color': $ConstCode.getCategoryById(
                            item.categoryId
                          ).color
                        }"
                      >
                        {{ item.categoryNm }}
                      </div>
                    </div>
                    <div class="right">
                      <!--<div class="pt_tag new"></div>-->
                    </div>
                  </div>
                  <div class="party_ttl">{{ item.title }}</div>
                  <div class="party_con">
                    <div class="left">
                      <div class="party_info">
                        <!--                        <dl v-if="!$Util.isEmpty(item.name)">-->
                        <!--                          <dt>신청자 :</dt>-->
                        <!--                          <dd>{{ item.name }}</dd>-->
                        <!--                        </dl>-->
                        <dl>
                          <dt>희망 이용 기간 :</dt>
                          <dd>
                            {{ `${item.usePeriod}일`
                            }}{{
                              `(최소 일 평균 ${(
                                Number(item.minPrice) / Number(item.usePeriod)
                              ).toFixed(0)}원)`
                            }}
                          </dd>
                        </dl>
                        <dl v-if="!$Util.isEmpty(item.searchWord)">
                          <dt>검색어 :</dt>
                          <dd>{{ item.searchWord }}</dd>
                        </dl>
                        <dl>
                          <dt>구매희망가 :</dt>
                        </dl>
                      </div>
                    </div>
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(item.minPrice, {
                            unit: ''
                          })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원 ~</span
                        ><!--띄어쓰기-->
                        <span class="value">{{
                          $Util.formatNumber2(item.maxPrice, { unit: '' })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnCreate">
                파티 생성
              </button>
              <nice
                :id="'formNice11'"
                :m="niceForm.m"
                :encode-data="niceForm.encodeData"
                :call-type="niceForm.callType"
                :rtn-url="rtnUrl"
                :fail-rtn-url="failRtnUrl"
                :callback="fnCallback"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-party-hope',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
