import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class WizzpediaSvc extends ServiceExec {
  /**
   * 총 개수
   * @param params
   * @returns {*}
   */
  postWizzpediaMain(params = {}) {
    return this.post(`/api/wizzpedia/wizzpediaMain`, params).then(response => {
      return response.data
    })
  }

  /**
   * 리스트
   * @param params
   * @returns {*}
   */
  postWizzpediaSearchWizzpedia(params = {}) {
    return this.post(`/api/wizzpedia/searchWizzpedia`, params).then(
      response => {
        return response.data
      }
    )
  }
  /**
   * 상세 페이지
   * @param params
   * @returns {*}
   */
  postWizzpediaGetWizzpediaDetail(params = {}) {
    return this.post(`/api/wizzpedia/getWizzpediaDetail`, params).then(
      response => {
        return response.data
      }
    )
  }
  /**
   * 댓글 리스트
   * @param params
   * @returns {*}
   */
  postWizzpediaGetCommentList(params = {}) {
    return this.post(`/api/wizzpedia/getCommentList`, params).then(response => {
      return response.data
    })
  }
  /**
   * 대댓글 리스트
   * @param params
   * @returns {*}
   */
  postGetNestedCommentList(params = {}) {
    return this.post(`/api/wizzpedia/getNestedCommentList`, params).then(
      response => {
        return response.data
      }
    )
  }
  /**
   * 댓글 작성
   * @param params
   * @returns {*}
   */
  postWizzpediaWriteComment(params = {}) {
    return this.post(`/api/wizzpedia/writeComment`, params).then(response => {
      return response.data
    })
  }
  /**
   * 댓글 수정
   * @param params
   * @returns {*}
   */
  postWizzpediaModifyComment(params = {}) {
    return this.post(`/api/wizzpedia/modifyComment`, params).then(response => {
      return response.data
    })
  }
  /**
   * 댓글 삭제
   * @param params
   * @returns {*}
   */
  postWizzpediaDeleteComment(params = {}) {
    return this.post(`/api/wizzpedia/deleteComment`, params).then(response => {
      return response.data
    })
  }
  /**
   * 댓글 추천/추천취소
   * @param params
   * @returns {*}
   */
  postWizzpediaUpdateCommentRecommend(params = {}) {
    return this.post(`/api/wizzpedia/updateCommentRecommend`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 대댓글 작성
   * @param params
   * @returns {*}
   */
  postWizzpediaWriteNestedComment(params = {}) {
    return this.post(`/api/wizzpedia/writeNestedComment`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 대댓글 수정
   * @param params
   * @returns {*}
   */
  postWizzpediaModifyNestedComment(params = {}) {
    return this.post(`/api/wizzpedia/modifyNestedComment`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 대댓글 삭제
   * @param params
   * @returns {*}
   */
  postWizzpediaDeleteNestedComment(params = {}) {
    return this.post(`/api/wizzpedia/deleteNestedComment`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 평점 리스트/검색
   * @param params
   * @returns {*}
   */
  postGetTotalCommentList(params = {}) {
    return this.post(`/api/wizzpedia/getTotalCommentList`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 마이페이지 평점관리
   * @param params
   * @returns {*}
   */
  postGetMyCommentList(params = {}) {
    return this.post(`/api/wizzpedia/getMyCommentList`, params).then(
      response => {
        return response.data
      }
    )
  }
}
export default new WizzpediaSvc()
