<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap" v-if="!data.isReComment">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div class="wzp_comment">
                <div class="rank">
                  <div class="con_ttl">작품에 대한 감상평을 남겨주세요.</div>
                  <div class="stars">
                    <ul>
                      <li
                        :class="item"
                        @click="fnScore(index)"
                        v-for="(item, index) in stars"
                        :key="index"
                      ></li>
                    </ul>
                  </div>
                  <label>
                    <textarea
                      placeholder="500자 이내로 작성"
                      v-model="form.comment"
                      :maxlength="500"
                    ></textarea>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal_wrap" v-else>
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>댓글 작성</span>
                </div>
                <div class="hint">
                  * 글 내용이 혐오 조장, 비속어 사용, 음란한 대화 등 공서 양식을
                  해치거나 플랫폼의 정책과 맞지 않은 경우 관리자에 의해 삭제될
                  수 있습니다.
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li class="form_li">
                        <div class="form_in">
                          <label>
                            <textarea
                              placeholder="50자 이내로 작성"
                              v-model="form.comment"
                              :maxlength="500"
                            ></textarea>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnSave">저장</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-comment',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
