import { reactive, toRefs, getCurrentInstance, computed } from 'vue'

export const componentState = ({ data, callback }) => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    pinList: [],
    computedPinList: computed(() =>
      state.pinList.map(el => {
        let text
        if (el.useDt) {
          text = `사용기한 ${proxy.$dayjs(el.useDt).format('YYYY-MM-DD')} 까지`
        } else {
          text = '기한 없음'
        }
        return { ...el, text }
      })
    ),
    checkList: [],
    count: computed(() => state.checkList.length),
    computedTotalPrice: computed(() => state.count * data.price)
  })

  const fnSave = async () => {
    if (!isValid()) return
    proxy.$emit('close')
    callback(state.checkList)
  }

  const isValid = () => {
    if (proxy.$Util.isEmpty(state.checkList)) {
      alert('상품을 선택해 주세요.')
      return false
    }
    return true
  }

  const init = async () => {
    const params = {
      marketNo: data.marketNo
    }
    const res = await proxy.$MarketSvc.idelPinList(params)
    state.pinList = res.pinList
  }
  init()

  return { ...toRefs(state), fnSave }
}
