import { getCurrentInstance, ref, onMounted } from 'vue'

export const componentState = ({ callback }) => {
  const { proxy } = getCurrentInstance()
  const loadCount = ref(0)

  const iframeOnload = () => {
    if (
      proxy.$Util.isEmpty(
        Object.values(
          document.getElementById('settlePop').contentWindow.location
        )
      )
    ) {
      if (
        loadCount.value > 1 &&
        document.querySelector('#settlePop').innerText.includes('위즈니')
      ) {
        console.log(document.querySelector('#settlePop').innerText, loadCount)
        proxy.$emit('close')
      }
    } else if (loadCount.value > 1) {
      proxy.$emit('close')
    }
    loadCount.value++
  }

  history.pushState(null, null, location.href)
  window.onpopstate = function() {
    history.go(1)
  }

  onMounted(() => callback('init'))

  return {
    iframeOnload
  }
}
