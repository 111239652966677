import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class MainSvc extends ServiceExec {
  /**
   * 메인 리스트
   * @param params
   * @returns {*}
   */
  postSubscribeShare(params = {}) {
    return this.post(`/api/main/subscribeShare`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 배너 리스트
   * @param params
   * @returns {*}
   */
  postPartyRollingBanner(params = {}) {
    return this.post(`/api/party/rollingBanner`, params).then(response => {
      return response.data
    })
  }

  /**
   * 마켓 배너 리스트
   * @param params
   * @returns {*}
   */
  postMarketRollingBanner(params = {}) {
    return this.post(`/api/market/rollingBanner`, params).then(response => {
      return response.data
    })
  }

  /**
   * 배너 리스트
   * @param params
   * @returns {*}
   */
  postRollingBanner(params = {}) {
    return this.post(`/api/main/rollingBanner`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 연장 결제 팝업 off
   * @param params
   * @returns {*}
   */
  postExtensionNotificationOff(params = {}) {
    return this.post(`/api/main/extension/notification/off`, params).then(
      response => {
        return response.data
      }
    )
  }

  // 2-3-8 판매자 거래중 상품 확인
  postTradingProduct(params = {}) {
    return this.post(`/api/trading/product`, params).then(res => res.data)
  }
}
export default new MainSvc()
