import { computed, watch, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { CommonFunction } from '@/common/CommonFunction'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    onDepth1Item: computed(() => getters['nav/getOnDepth1Item']),
    categoryList: computed(() => getters['nav/getCategoryList']),
    text: '',
    isShow: false,
    isShowCommunity: computed(() => route.path.indexOf('/community') > -1),
    isShowMyPage: computed(() => route.path.indexOf('/my-page') > -1),
    isShowCS: computed(() => route.path.indexOf('/cs') > -1),
    myPageBadge: computed(() => getters['myPage/getMyPageBadge']),
    activeTab: Number(route.query.activeTab)
  })
  const fnPage = async item => {
    // await dispatch('nav/updateOnDepth1Item', { onDepth1Item: item })
    if (item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['1000'].id) {
      state.activeTab = 0
      await switchTab(0)
      // await router.push({ path: '/party/list', query: {activeTab: 0} })
    } else if (item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['2000'].id) {
      state.activeTab = 1
      await switchTab(1)
      // await router.push({ path: '/market/list' })
    } else if (item.categoryID === proxy.$ConstCode.CATEGORY_VALUE['3000'].id) {
      state.activeTab = 2
      await switchTab(2)
    }
  }

  const fnToggle = () => {
    state.isShow = !state.isShow
    if (!state.isShow) {
      state.text = ''
    }
  }
  const fnUnion = async () => {
    await router.push({ path: '/main/search', query: { search: state.text } })
  }
  const fnMyPage = async () => {
    if (!proxy.$Util.isEmpty(state.myPageBadge) && state.myPageBadge > 0) {
      await dispatch('myPage/updateMyPageBadge', { myPageBadge: 0 })
    }
    await router.push({ name: 'my-page-home-index' })
  }
  const { switchTab, start, move, end, setTouchPos } = CommonFunction(state)
  /** watch **/
  watch(
    () => route.fullPath,
    () => {
      state.isShow = false
      state.text = ''
    }
  )
  return {
    ...toRefs(state),
    fnPage,
    fnToggle,
    fnUnion,
    fnMyPage,
    switchTab,
    start,
    move,
    end,
    setTouchPos
  }
}
