<template>
  <div class="modal_dim on">
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>파티장이 등록한 계정을 확인하시겠습니까?</span>
                </div>
                <div class="hint">
                  ※ 계정을 확인할 경우 단순 변심 취소가 불가능합니다.
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnConfirm">확인</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-share-info-check',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>
