<template>
  <div class="inner">
    <div class="depth02">
      <div class="left">
        <div class="btn_wrap">
          <button
            :class="{ on: tabIndex === item.index }"
            @click="fnTabIndex(item.index)"
            v-for="(item, index) in tabs"
            :key="index"
          >
            <span>{{ item.name }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'nav-bar-cs-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
