<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="con_ttl">
                  <span>{{ '상품 정보 ' + (isEdit ? '수정' : '등록') }}</span>
                </div>
                <div class="form_wrap">
                  <div class="form">
                    <ul class="form_ul">
                      <li v-if="!isEdit" class="form_li">
                        <div class="form_ttl essen">상품 수량(최대 5개)</div>
                        <div class="form_in">
                          <label class="select">
                            <select v-model="addCount">
                              <option
                                v-for="(count, index) in optionCountList"
                                :key="index"
                                :value="count + 1"
                                >{{ count + 1 }}</option
                              >
                            </select>
                          </label>
                        </div>
                      </li>
                      <li class="form_li">
                        <div class="form_ttl">
                          사용 기간 (미 입력 시 무제한)
                        </div>
                        <div class="form_in">
                          <div class="inline">
                            <label class="input">
                              <input
                                type="date"
                                max="2099-12-31"
                                placeholder="YYYY-MM-DD"
                                maxlength="10"
                                v-model="useDt"
                              />
                            </label>
                          </div>
                        </div>
                      </li>
                      <li class="form_li">
                        <div class="form_ttl">인증번호 및 기타번호</div>
                        <div class="form_in">
                          <label class="input">
                            <input
                              type="number"
                              inputmode="decimal"
                              pattern="\d*"
                              :maxlength="50"
                              placeholder="숫자만 입력해 주세요."
                              @input="maskingExNumber"
                              :value="exNum"
                            />
                          </label>
                          <div class="hint">
                            * 제품 특성에 따라 추가 인증번호와 같은 기타번호가
                            있을 경우 PIN번호와 함께 입력해주세요.
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" v-if="isEdit" @click="fnSave">수정</button>
              <button class="btn" v-else @click="fnSave">등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'modal-alert-goods-add',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    },
    component: {
      type: [Object],
      default: () => {}
    }
  },
  emits: ['close'],
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
