<template>
  <div class="depth02">
    <div class="left">
      <div class="btn_wrap">
        <button
          :class="{ on: tabIndex === item.index }"
          v-for="(item, index) in $ConstCode.COMMUNITY_TYPE"
          :key="index"
          @click="fnPage(item.index)"
        >
          <span>{{ item.name }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'nav-bar-category-community-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
