import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    isNotShowWizzTalkInfo: proxy.$ConstCode.BOOLEAN_VALUE.FALSE
  })
  const fnIsNotShowWizzTalkInfo = async () => {
    let isNotShowWizzTalkInfo = getters['wizzTalk/getIsNotShowWizzTalkInfo']
    const id = state.userData.userId || state.userData.snsId
    const temp = isNotShowWizzTalkInfo.filter(item => {
      return item.id !== id
    })
    temp.push({ id: id, value: state.isNotShowWizzTalkInfo })
    await dispatch('wizzTalk/updateIsNotShowWizzTalkInfo', {
      isNotShowWizzTalkInfo: temp
    })
  }
  const fnClose = () => {
    if (props.callback) {
      props.callback()
    }
    proxy.$emit('close')
  }
  return { ...toRefs(state), fnIsNotShowWizzTalkInfo, fnClose }
}
