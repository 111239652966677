import { computed, watch, getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'

export const componentState = () => {
  const { getters, dispatch } = useStore()
  const route = useRoute()
  const router = useRouter()
  const { proxy } = getCurrentInstance()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    modalAlertComponents: computed(() => getters['layout/getModalAlertComponents']),
    modalComponents: computed(() => getters['layout/getModalComponents']),
    pollingTimer: null,
    bannerList: {},
    activeIndex: 0,
    bannerShow: false,
    routeName: computed(() => {
      return route.name
    })
  })

  /** methods **/
  const fnCloseModalAlert = async index => {
    const lastIndex =
      index ||
      (state.modalAlertComponents.length > 0
        ? state.modalAlertComponents.length - 1
        : 0)
    await dispatch('layout/closeModalAlertComponent', { index: lastIndex })
  }
  const fnRemoveAllModalAlertComponent = async () => {
    await dispatch('layout/removeAllModalAlertComponent')
  }
  const fnCloseModal = async index => {
    const lastIndex =
      index ||
      (state.modalComponents.length > 0 ? state.modalComponents.length - 1 : 0)
    await dispatch('layout/closeModalComponent', { index: lastIndex })
  }
  const fnRemoveAllModalComponent = async () => {
    await dispatch('layout/removeAllModalComponent')
  }
  const initPolling = async () => {
    if (proxy.$Util.isEmpty(state.pollingTimer)) {
      if (!proxy.$Util.isEmpty(state.userData)) {
        await dispatch('myPage/fetchCheckChatReadYn')
      }
      // state.pollingTimer = setInterval(async () => {
      //   if (!proxy.$Util.isEmpty(state.userData)) {
      //     await dispatch('myPage/fetchCheckChatReadYn')
      //   }
      // }, proxy.$ConstCode.CODE_ENV_LIST.POLLING_SECOND * 1000)
    }
  }
  const clearPolling = () => {
    if (state.pollingTimer) {
      clearInterval(state.pollingTimer)
      state.pollingTimer = null
    }
  }

  const fnBannerList = async () => {
    let res
    if (state.routeName === 'party-list-index') {
      res = await proxy.$MainSvc.postPartyRollingBanner()
    } else if (state.routeName === 'market-list-index') {
      res = await proxy.$MainSvc.postMarketRollingBanner()
    } else {
      res = await proxy.$MainSvc.postRollingBanner()
    }
    state.bannerList = res.list
  }

  const onSlideChange = event => {
    state.activeIndex = event.activeIndex
  }

  const displayNone = e => {
    e.target.style.display = 'none'
  }

  const fnMove = async item => {
    const url = new URL(item.bannerUrl)
    let query = {}
    for (let [key, value] of url.searchParams.entries()) {
      query = { ...query, [key]: value }
    }

    router.push({ path: url.pathname, query })
  }

  /** watch route 이동시 side-bar, modal close **/
  watch(
    () => route.fullPath,
    async () => {
      const bannerShowRouteName = [
        'main-index',
        'party-list-index',
        'market-list-index'
      ]
      state.bannerShow = bannerShowRouteName.includes(route.name)
      if (!proxy.$Util.isEmpty(state.modalAlertComponents)) {
        await fnRemoveAllModalAlertComponent()
      }
      if (!proxy.$Util.isEmpty(state.modalComponents)) {
        await fnRemoveAllModalComponent()
      }
      await fnBannerList()
    }
  )
  watch(
    () => state.userData.wizztalk,
    async val => {
      if (proxy.$Util.isEmpty(val)) return
      if (Number(val) === proxy.$ConstCode.BOOLEAN_VALUE.TRUE) {
        console.log('initPolling')
        setTimeout(async () => {
          await initPolling()
        }, 2000)
      } else {
        console.log('clearPolling')
        clearPolling()
      }
    },
    { immediate: true }
  )
  /** init **/
  const init = async () => {
    await fnBannerList()
    if (proxy.$Util.isEmpty(state.categoryList)) {
      await dispatch('nav/fetchCategoryList')
    }
  }
  init()
  // if (
  //   !proxy.$Util.isEmpty(state.userData) &&
  //   Number(state.userData.wizztalk) === proxy.$ConstCode.BOOLEAN_VALUE.TRUE
  // ) {
  //   setTimeout(async () => {
  //     await initPolling()
  //   }, 1000)
  // }

  return {
    fnCloseModalAlert,
    fnCloseModal,
    onSlideChange,
    ...toRefs(state),
    fnMove,
    displayNone,
    route
  }
}
