<template>
  <div class="modal_dim on">
    <!--'on 추가'-->
    <div class="modal_wrap">
      <div class="modal_inner center">
        <div class="modal">
          <div class="modal_con">
            <div class="modal_txt">
              <div>
                <div class="party_list_li" style="height: auto;">
                  <div class="market_thumb">
                    <div class="img_area">
                      <img :src="data.thumbnail" alt="" />
                    </div>
                  </div>
                  <div class="party_ttl" style="padding-top: 1rem;">
                    {{ data.title }}
                  </div>
                  <div class="party_con" style="position: unset;">
                    <div class="right">
                      <div class="party_price">
                        <span class="value">{{
                          $Util.formatNumber2(data.price, { unit: '' })
                        }}</span
                        ><!--띄어쓰기-->
                        <span class="unit">원</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="$emit('close')">취소</button>
              <button class="btn" @click="fnRegenerate">재등록</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index'

export default {
  name: 'modal-alert-market-re-regist',
  props: {
    index: {
      type: [Number],
      default: 0
    },
    data: {
      type: [Object],
      default: () => {}
    },
    callback: {
      type: [Function],
      default: () => {}
    }
  },
  setup(props) {
    return { ...componentState(props) }
  }
}
</script>

<style scoped></style>
