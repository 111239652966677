import router from '@/router'
import ChatSvc from '@/common/service/ChatSvc'
import dayjs from 'dayjs'

export const state = {
  tabIndex: 0,
  readYn: 'N',
  myPageBadge: 0
}
export const getters = {
  getTabIndex: state => {
    return state.tabIndex
  },
  getReadYn: state => {
    return state.readYn
  },
  getMyPageBadge: state => {
    state.myPageBadge = state.myPageBadge || localStorage.myPageBadge
    return Number(state.myPageBadge)
  }
}
export const mutations = {
  setTabIndex(state, { tabIndex }) {
    state.tabIndex = tabIndex
  },
  setReadYn(state, { readYn }) {
    state.readYn = readYn
  },
  setMyPageBadge(state, { myPageBadge }) {
    state.myPageBadge = myPageBadge
    localStorage.myPageBadge = myPageBadge
  }
}
export const actions = {
  updateTabIndex({ commit }, { tabIndex = 0 }) {
    commit('setTabIndex', { tabIndex })
  },
  async fetchCheckChatReadYn({ commit, dispatch }) {
    const res = await ChatSvc.postCheckChatReadYn()
    if (res.resultCode === '0000') {
      // const readYn = getters['getReadYn']
      // if (readYn !== res.readYn && readYn === 'Y') {
      if (res.readYn === 'N') {
        // 새로운 위즈톡 메시지 발생
        const pushAlarm = {
          title: '새로운 메세지가 확인되었습니다.',
          notification: {},
          data: {},
          regDt: dayjs(),
          callback: index => {
            dispatch('pushAlarm/popPushAlarm', { index: index }, { root: true })
            router.push({ path: '/my-page/wizz-talk' })
          }
        }
        dispatch(
          'pushAlarm/addPushAlarm',
          { pushAlarm: pushAlarm },
          { root: true }
        )
      }
      commit('setReadYn', { readYn: res.readYn })
    } else {
      console.error(res.resultMsg)
    }
  },
  updateMyPageBadge({ commit }, { myPageBadge = 0 }) {
    commit('setMyPageBadge', { myPageBadge })
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
