import { reactive, toRefs, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    tabIndex: 0,
    color: computed(() => (state.tabIndex === 0 ? '#00132A' : '#404040')),
    isNotShowInfo: proxy.$ConstCode.BOOLEAN_VALUE.FALSE
  })
  const fnIsNotShowInfo = async () => {
    let isNotShowInfo = getters['party/getIsNotShowInfo']
    const id = state.userData.userId || state.userData.snsId
    const temp = isNotShowInfo.filter(item => {
      return item.id !== id
    })
    temp.push({ id: id, value: state.isNotShowInfo })
    await dispatch('party/updateIsNotShowInfo', {
      isNotShowInfo: temp
    })
  }
  return { ...toRefs(state), fnIsNotShowInfo }
}
