import ServiceExec from '@/common/utils/ServiceExec'
/**
 * Common API Service
 * @class SignSvc
 */
class PartySvc extends ServiceExec {
  /**
   * 영상/기타 리스트 (검색 포함)
   * @param params
   * @returns {*}
   */
  postPartyList(params = {}) {
    return this.post(`/api/party/list`, params).then(response => {
      return response.data
    })
  }
  /**
   * 영상/기타 리스트 (검색 포함)
   * @param params
   * @returns {*}
   */
  postPartyAddForm(params = {}) {
    return this.post(`/api/party/addForm`, params).then(response => {
      return response.data
    })
  }
  /**
   * 파티 생성
   * @param params
   * @returns {*}
   */
  postPartyAdd(params = {}) {
    return this.post(`/api/party/add`, params).then(response => {
      return response.data
    })
  }
  /**
   * 파티 상세페이지
   * @param params
   * @returns {*}
   */
  postPartyDetail(params = {}) {
    return this.post(`/api/party/detail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 결제 페이지
   * @param params
   * @returns {*}
   */
  postPartySubscribe(params = {}) {
    return this.post(`/api/party/subscribe`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 희망 게시판 리스트
   * @param params
   * @returns {*}
   */
  postPartyHopeList(params = {}) {
    return this.post(`/api/party/hopeList`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 희망 게시판 리스트
   * @param params
   * @returns {*}
   */
  postPartyPartyHopeDetail(params = {}) {
    return this.post(`/api/party/partyHopeDetail`, params).then(response => {
      return response.data
    })
  }

  /**
   * 참여한 파티 취소/환불
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundRequest(params = {}) {
    return this.post(`/api/party/cancelRefundRequest`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 참여한 파티 취소/환불 편집
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundModify(params = {}) {
    return this.post(`/api/party/cancelRefundModify`, params).then(response => {
      return response.data
    })
  }

  /**
   * 참여한 파티 분쟁취소
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundDelete(params = {}) {
    return this.post(`/api/party/cancelRefundDelete`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 취소/환불
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundAccept(params = {}) {
    return this.post(`/api/party/cancelRefundAccept`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 신청반려
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundReject(params = {}) {
    return this.post(`/api/party/cancelRefundReject`, params).then(response => {
      return response.data
    })
  }

  /**
   * 생성한 파티 신청반려 편집
   * @param params
   * @returns {*}
   */
  postPartyCancelRefundModifyReply(params = {}) {
    return this.post(`/api/party/cancelRefundModifyReply`, params).then(
      response => {
        return response.data
      }
    )
  }

  /**
   * 파티 알림 설정 등록
   * @param params
   * @returns {*}
   */
  postPartyUpdateHope(params = {}) {
    return this.post(`/api/party/updateHope`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 알림 설정 삭제
   * @param params
   * @returns {*}

   */
  postDeleteHope(params = {}) {
    return this.post(`/api/party/deleteHope`, params).then(response => {
      return response.data
    })
  }

  /**
   * 파티 공유 계정 정보 확인
   * @param params
   * @returns {*}

   */
  postConfirmShare(params = {}) {
    return this.post(`/api/party/confirm/share`, params).then(response => {
      return response.data
    })
  }
}
export default new PartySvc()
